import { ReactElement } from 'react';
import styles from './CheckboxInput.module.css';

export const CheckboxInput = ({
  id,
  label,
  name,
  value,
  checked,
  onChange,
}: PropTypes) => (
  <div className={styles.inputContainer}>
    <input
      id={id}
      name={name}
      className={styles.input}
      type="checkbox"
      value={value}
      checked={checked}
      onChange={(event) => onChange(event.target.value)} />
    <label htmlFor={`#${id}`} className={styles.inputLabel} onClick={() => onChange(value)}>{label}</label>
  </div>
);

type PropTypes = {
  id: string;
  label: string | ReactElement;
  name: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
}