import styles from './FullscreenLoader.module.css';
import loader from '../../../img/loaderIcon.png';

export const FullscreenLoader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div style={{ display: isLoading ? 'flex' : 'none' }} className={styles.modal}>
      <div className={styles['modal-content']}>
        <div className={styles['lds-spinner']}>
          <div className={styles.loaderContainer}>
            <img src={loader} alt="loader" />
          </div>
          <div className={styles.loaderContainer}>
            <img src={loader} alt="loader" />
          </div>
          <div className={styles.loaderContainer}>
            <img src={loader} alt="loader" />
          </div>
          <div className={styles.loaderContainer}>
            <img src={loader} alt="loader" />
          </div>
          <div className={styles.loaderContainer}>
            <img src={loader} alt="loader" />
          </div>
          <div className={styles.loaderContainer}>
            <img src={loader} alt="loader" />
          </div>
        </div>
        <div className={styles['modal-text']}>
          <i>
            Você está a poucos passos de ficar mais seguro...
          </i>
        </div>
      </div>
    </div>
  )
}