import { useCallback, useContext, useEffect, useState } from 'react';
import { TextInput } from '../../../UI/text-input/TextInput';
import styles from './Identificacao.module.css';
import FormContext from '../../../../store/form-context';
import { Button } from '../../../UI/button/Button';
import MultiStepsContext from '../../../../store/multi-steps-form-context';
import sharedStyles from '../../../SharedStyles.module.css';
import * as Yup from 'yup';
import useYupSchema from '../../../../hooks/use-yup-schema';
import { ErrorMessage } from '../../../error-message/ErrorMessage';
import { isEmpty } from 'lodash';

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Por favor, informe o seu nome para iniciar"),
});

export const Identificacao = () => {
  const { goForward } = useContext(MultiStepsContext);
  const { name, updateState } = useContext(FormContext);
  const { isValid, errors } = useYupSchema({ name }, validationSchema);
  const [forwardPressed, setForwardPressed] = useState(false);

  const validate = useCallback(() => {
    if (isValid) {
      goForward();
      return;
    }

    setForwardPressed(true);
  }, [isValid, goForward]);

  useEffect(() => {
    if (!isEmpty(name)) return;

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    updateState({ name: params.get('name') || '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={sharedStyles.container}>
      <h1 className={styles.title}>Simule e contrate agora</h1>
      <div className={styles.content}>
        <TextInput
          id="name"
          label="Nome completo"
          type="text"
          placeholder="Olá Dr(a). como você se chama?"
          value={name}
          onChange={(value) => updateState({ name: value })}
        />
        <ErrorMessage message={errors.name} isVisible={forwardPressed && !!errors.name} />
      </div>
      <div className={styles.buttonContainer}>
        <Button label="Começar" onClick={validate} />
      </div>
    </div>
  );
};