import { useContext, useMemo } from 'react';
import styles from './ProgressBar.module.css';
import FormContext from '../../../../store/form-context';

export const ProgressBar = ({ step }: { step: number }) => {
  const { categoria, renovacao } = useContext(FormContext);

  const { labelSecondStep, secondReached, thirdReached } = useMemo(() => {
    const isCirurgiao = categoria === 'PLASTIC-SURGERY'
    const isRenovacao = renovacao === 'renovacao';
    const labelSecondStep = isRenovacao ? 'DADOS' : 'SIMULAÇÃO';
    let config;

    if (isRenovacao) {
      config = {
        secondReached: 6,
        thirdReached: 7,
      }
    } else {
      config = {
        secondReached: isCirurgiao ? 5 : 6,
        thirdReached: isCirurgiao ? 6 : 7,
      }
    }

    return {
      labelSecondStep,
      ...config,
    };
  }, [categoria, renovacao]);

  const second = step >= secondReached && styles.reached;
  const third = step >= thirdReached && styles.reached;

  if (step === 0) return <></>;

  return (
    <div className={styles.container}>
      <div className={`${styles.item} ${styles.reached}`}>
        <span className={styles.text}>INFORMAÇÕES</span>
      </div>
      <div className={`${styles.item} ${second}`}>
        <span className={styles.text}>{labelSecondStep}</span>
      </div>
      <div className={`${styles.item} ${third}`}>
        <span className={styles.text}>CONTRATO</span>
      </div>
    </div>
  )
};
