import { useCallback, useState } from 'react';
import styles from './DateInput.module.css';
import { MaskType, applyMask } from '../../../utils/mask';

export const DateInput = ({
  id,
  label,
  type,
  placeholder,
  value,
  theme,
  maskType,
  disabled = false,
  onChange: onChangeCallback,
}: PropTypes) => {
  const [newValue, setNewValue] = useState(value || '');
  const inputStyle = theme === 'light' ? styles.inputLight : styles.inputDefault;
  const onBlur = useCallback(() => {
    if (!onChangeCallback) return;

    const trimmedValue = newValue.trim();

    onChangeCallback(trimmedValue);
  }, [newValue, onChangeCallback])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (maskType) {
      setNewValue(applyMask({ maskType, value: event.target.value }));
      return;
    }

    setNewValue(event.target.value)
  }

  return (
    <div className={styles.inputContainer}>
      {label && (
        <label
          htmlFor={`#${id}`}
          className={styles.inputLabel}
        >
          {label}
        </label>
      )}
      <input
        id={id}
        className={`${styles.input} ${inputStyle}`}
        type={type}
        placeholder={placeholder}
        value={newValue}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );
};

type PropTypes = {
  id: string;
  label?: string;
  type: React.HTMLInputTypeAttribute;
  placeholder?: string;
  value?: string;
  theme?: 'default' | 'light';
  maskType?: MaskType;
  disabled?: boolean;
  onChange?: (value: string) => void;
}