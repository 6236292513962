import { useContext, useEffect, useMemo, useRef } from "react";
import FormContext from "../../../../../../store/form-context";
import styles from './Coberturas.module.css';
import { size } from "lodash";
import { formatNumber } from "../../../../../../utils/formatNumber";
import icon from '../../../../../../img/tick-circle.png';
import { PROTECAO_COBERTURAS } from "../../../../../../utils/constants";
import { Collapse } from "../../../../../UI/collapse/Collapse";
import useWindowDimensions from "../../../../../../hooks/use-window-dimensions";
import { CotacaoData } from "../../../../../../types/api-corsier";
import { SimulacaoData } from "../../../../../../types/global";

const NUMBER_OPTIONS = { style: 'currency', currency: 'BRL' };

const ProtecaoItem = ({ label }: { label: string }) => (
  <div className={styles.item}>
    <img className={styles.icon} src={icon} alt="icone" />
    <p>{label}</p>
  </div>
);

const Items = () => {
  const { left, right } = PROTECAO_COBERTURAS;
  return (
    <div className={styles.containerItem}>
      <div className={styles.contentItem}>
        {
          left.concat(right).map((item) => <ProtecaoItem key={item} label={item} />)
        }
      </div>
    </div>
  );
};

export const Coberturas = ({ showingError }: { showingError: boolean }) => {
  const { cobertura, simulacaoData } = useContext(FormContext);
  const coberturasRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();

  const coberturaSelecionada = useMemo<SimulacaoData | undefined>(() => {
    if (!simulacaoData) return;

    const simulacao = simulacaoData.cotacoes?.find(
      (cotacao: CotacaoData) => cotacao.cobertura === cobertura
    );
    const temp = simulacao!.quotation.formasDePagto.CreditCard || [];
    const index = size(simulacao!.quotation.formasDePagto.CreditCard) - 1;

    return {
      id: simulacao!.quotation.quotationIdentifier,
      coberturaCotacao: simulacao!.cobertura,
      valor: simulacao!.quotation.valor_total,
      parcelas: temp[index].parcelas,
      valorParcela: temp[index].valor,
    };
  }, [cobertura, simulacaoData]);


  const valorParcelaFormatted = useMemo(() => {
    if (!coberturaSelecionada?.valorParcela) return 0;

    return formatNumber(coberturaSelecionada.valorParcela, NUMBER_OPTIONS);
  }, [coberturaSelecionada?.valorParcela]);


  const valorFormatted = useMemo(() => {
    if (!coberturaSelecionada?.valor) return 0;

    return formatNumber(+coberturaSelecionada.valor, NUMBER_OPTIONS);
  }, [coberturaSelecionada?.valor]);

  useEffect(() => {
    if (width < 800) return;
    let lastScrollPosition = 0;

    const handleScroll = () => {
      if (!coberturasRef.current) return;

      let scrollTop = window.scrollY;

      const target = showingError ? 410 : 330;

      if (scrollTop > lastScrollPosition) {
        if (window.scrollY < 250) return;
        coberturasRef.current.style.transform = `translateY(-${target}px)`;
      } else {
        if (window.scrollY > 480) return;
        coberturasRef.current.style.transform = `translateY(${0}px)`;
      }

      lastScrollPosition = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showingError, width]);


  if (!coberturaSelecionada) return null;

  return (
    <div ref={coberturasRef} className={styles.contentContainer}>
      <div>
        <p className={styles.title}>Valor do seguro</p>
      </div>
      <div>
        <p className={styles.text}>{coberturaSelecionada.parcelas}x sem juros de</p>
        <p className={styles.mainText}>{valorParcelaFormatted}</p>
      </div>
      <div className={styles.divider}>
        <hr />
      </div>
      <div className={styles.contentSmallText}>
        <p>À vista {valorFormatted}</p>
      </div>
      <Collapse content={<Items />} title="Coberturas" />
    </div>
  );
}
