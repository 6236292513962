import styles from './Select.module.css';

export const Select = ({ name, options, theme, disabled = false, onChange }: SelectProps) => {
  const style = theme === 'button' ? styles.selectButton : styles.selectDefault;
  return (
    <div className={styles.selectContainer}>
      <select id={name} className={`${styles.select} ${style}`} disabled={disabled} name={name} onChange={onChange}>
        {
          options.map(({ id, value, label, selected }) => (
            <option
              key={value}
              id={id}
              value={value}
              selected={selected}
            >
              {label}
            </option>
          ))
        }
      </select>
    </div>
  );
};

export type Option = {
  id: string;
  value: string | number;
  selected: boolean;
  label: string;
}

type SelectProps = {
  name: string;
  options: Option[];
  theme?: 'default' | 'button';
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}