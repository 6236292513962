import sharedStyles from '../../../SharedStyles.module.css';
import { useCallback, useContext, useMemo, useState, } from "react";
import MultiStepsContext from "../../../../store/multi-steps-form-context";
import { NavigationButtons } from "../navigation-buttons/NavigationButtons";
import styles from './Apolice.module.css';
import { FileInput } from '../../../UI/file-input/FileInput';
import FormContext from '../../../../store/form-context';
import { SEGURADORAS } from '../../../../utils/constants';
import { ErrorMessage } from '../../../error-message/ErrorMessage';
import Yup from '../../../../utils/yup';
import useYupSchema from '../../../../hooks/use-yup-schema';

const validationSchema = Yup.object().shape({
  seguradoraAtual: Yup.string().required("Por favor, selecione a seguradora atual"),
});

export const Apolice = () => {
  const { goForward, goBack } = useContext(MultiStepsContext);
  const { name, seguradoraAtual, updateState } = useContext(FormContext);
  const [forwardPressed, setForwardPressed] = useState(false);

  const { isValid, errors } = useYupSchema(
    { seguradoraAtual },
    validationSchema,
  );

  console.log(errors)
  const optionsSeguradora = useMemo(
    () => SEGURADORAS.map(({ value, label, id }) => (
      <option
        key={value}
        id={id}
        value={value}
        selected={seguradoraAtual === value}
      >
        {label}
      </option>
    )),
    [seguradoraAtual],
  );

  const onChangeSeguradora: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    updateState({ seguradoraAtual: event.target.value })
  };

  const validate = useCallback(() => {
    setForwardPressed(true);

    console.log('validade', isValid)

    if (!isValid) return;

    goForward();
  }, [isValid, goForward]);

  return (
    <div className={sharedStyles.container}>
      <div className={styles.content}>
        <div>
          <p className={sharedStyles.text}>
            Qual é a sua seguradora atual <span className={sharedStyles.emphasis}>Dr(a). {name}</span>
          </p>
        </div>
        <div className={styles.seguradoraContainer}>
          <div className={styles.seguradoras}>
            <label htmlFor="select-seguradora">Selecione a sua seguradora</label>
            <select
              id="select-seguradora"
              className={styles.select}
              name="select-seguradora"
              onChange={onChangeSeguradora}>
              {optionsSeguradora}
            </select>
            <ErrorMessage message={errors.seguradoraAtual} isVisible={forwardPressed && !!errors.seguradoraAtual} />
          </div>
        </div>
        <div>
          <p className={sharedStyles.text}>
            Se quiser, nos envie uma cópia da apólice anterior
          </p>
        </div>
        <FileInput />
        <div>
          <p className={styles.text}>
            Se preferir, você poderá enviar posteriormente para ser analisada pelo nosso corretor.
          </p>
        </div>

        <div className={sharedStyles.marginNavigationButtons}>
          <NavigationButtons onClickForward={validate} onClickBack={goBack} />
        </div>
      </div>
    </div >
  );
}