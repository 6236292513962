import styles from './CotacaoCard.module.css';
import sharedStyles from '../../../../../SharedStyles.module.css';
import { formatNumber } from '../../../../../../utils/formatNumber';
import contrato from '../../../../../../img/contrato.png';

const NUMBER_OPTIONS = { style: 'currency', currency: 'BRL' };

export const CotacaoCard = ({
  value,
  valor,
  valorParcela,
  valorAVista,
  recommended,
  selected,
  parcelas,
  onSelect,
}: CotacaoCardProps) => {
  const selectedContainerStyles = selected && styles.selected;
  const selectedTextStyles = selected && styles.selectedText;
  const buttonStyles = selected ? styles.default : styles.clear;
  const valorFormatado = valor.toString() === '1000000' ? '1 milhão' : `${valor.toString().slice(0, 3)} mil`;
  const valorParcelaFormatted = formatNumber(valorParcela || 0, NUMBER_OPTIONS);
  const valorAVistaFormatted = formatNumber(valorAVista || 0, NUMBER_OPTIONS);


  return (
    <div>
      {recommended &&
        <div className={styles.recommendedIndicatorContainer}>
          <img className={styles.icon} src={contrato} alt="icone" />
          <span className={`${styles.recommendedIndicator} ${sharedStyles.emphasis}`}>
            Mais contratado
          </span>
        </div>
      }
      <div className={`${styles.container} ${selectedContainerStyles}`}>
        <div className={`${styles.titleContainer} ${selectedTextStyles}`}>
          <p className={styles.headerText}>Importância segurada</p>
          <p className={styles.headerTextBold}>R$ {valorFormatado}</p>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.contentText}>
            <p><span className={sharedStyles.emphasis}>{parcelas}x</span> sem juros de</p>
            <p className={sharedStyles.emphasis}>{valorParcelaFormatted}</p>
          </div>
          <div className={styles.contentSmallText}>
            <p>À vista {valorAVistaFormatted}</p>
          </div>
          <div className={styles.buttonContainer}>
            <button
              type="button"
              className={`${styles.button} ${buttonStyles}`}
              onClick={() => onSelect(value)}
            >
              Contratar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

type CotacaoCardProps = {
  value: number;
  valor: number;
  valorParcela: number;
  valorAVista: number;
  recommended: boolean;
  selected: boolean;
  parcelas: number;
  onSelect: (value: number) => void;
};