import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import updateLocale from 'dayjs/plugin/updateLocale';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.locale('pt-br');
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat)