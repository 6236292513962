import React, { ReactElement, useRef, useState } from 'react';
import styles from './Carousel.module.css';
import leftArrow from '../../../img/left-arrow.png';
import rightArrow from '../../../img/right-arrow.png';
import useWindowDimensions from '../../../hooks/use-window-dimensions';

export const Carousel = ({ items }: PropTypes) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [currentPosition, setCurrentPosition] = useState(0);
  const cardSize = +((carouselRef.current?.scrollWidth || 0) / items.length).toFixed(0);
  const { width } = useWindowDimensions();
  const visibleItems = width > 800 ? 3 : 1;
  const hiddenSize = +((carouselRef.current?.scrollWidth || 0) - (cardSize * visibleItems)).toFixed(0);

  const onClickPrevious = () => {
    if (!carouselRef.current || currentPosition === 0) return;

    const nextPosition = currentPosition - cardSize;
    carouselRef.current.style.transform = `translateX(-${nextPosition}px)`;
    setCurrentPosition(nextPosition);
  };

  const onClickNext = () => {
    if (!carouselRef.current) return;

    if (currentPosition >= hiddenSize) {
      setCurrentPosition(0);

      carouselRef.current.style.transform = `translateX(-${0}px)`;
      return;
    }

    const newPosition = currentPosition + cardSize;
    carouselRef.current.style.transform = `translateX(-${newPosition}px)`;
    setCurrentPosition(newPosition);
  };

  return (
    <div>
      <div className={styles.carouselContainer}>
        <div className={styles.carousel}>
          <div ref={carouselRef} className={styles.inner}>
            {items.map((Item) => <React.Fragment key={Item.key}>{Item}</React.Fragment>)}
          </div>
        </div>
      </div>
      <div>
        <button className={styles.button} type='button' onClick={onClickPrevious}>
          <img className={styles.icon} src={leftArrow} alt='' />
        </button>
        <button className={styles.button} type='button' onClick={onClickNext}>
          <img className={styles.icon} src={rightArrow} alt='' />
        </button>
      </div>
    </div>
  );
}

type PropTypes = {
  items: ReactElement[];
}