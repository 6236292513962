import { useEffect, useMemo, useRef, useState } from 'react';
import { get, isEqual, isUndefined, set } from 'lodash';
import Yup from '../utils/yup';

type ErrorsTypeArray<Values extends any[]> =
  | (string | string[])
  | (Values[number] extends object ? ErrorsType<Values[number]>[] : never);

export type ErrorsType<Values> = {
  [K in keyof Values]?:
  Values[K] extends any[] ? ErrorsTypeArray<Values[K]> :
  Values[K] extends object ? ErrorsType<Values[K]> : string
};

const yupToFormErrors = <T>(yupError: Yup.ValidationError) => {
  let errors: ErrorsType<T> = {};

  const setError = (error: Yup.ValidationError) => {
    if (isUndefined(error.path) || get(errors, error.path)) return;

    errors = set(errors, error.path, error.message);
  };

  if (yupError.inner.length) {
    yupError.inner.forEach(setError);
  } else {
    setError(yupError);
  }

  return errors;
};

const DEFAULT_ERRORS = Object.seal({});

const useYupSchema = <T extends Record<string, any>>(
  value: T,
  schema: Yup.AnySchema,
  context?: any,
) => {
  const [errors, setErrors] = useState<ErrorsType<T>>(DEFAULT_ERRORS);
  const [isValid, setIsValid] = useState(false);
  const prevValue = useRef<T | undefined>();
  const prevContext = useRef<T | undefined>();

  useEffect(() => {
    const updatePrevValue = () => {
      prevValue.current = value;
    };

    return () => updatePrevValue();
  }, [value]);

  useEffect(() => {
    const updatePrevContext = () => {
      prevContext.current = context;
    };

    return () => updatePrevContext();
  }, [context]);

  useEffect(() => {
    const valueIsDeepEqual = isEqual(value, prevValue.current);
    const contextIsDeepEqual = isEqual(context, prevContext.current);

    if (valueIsDeepEqual && contextIsDeepEqual) return;

    const validate = async () => {
      try {
        await schema.validate(value, { abortEarly: false, context });

        setErrors(DEFAULT_ERRORS);
        setIsValid(true);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          setErrors(yupToFormErrors<T>(error));
          setIsValid(false);
        } else {
          throw error;
        }
      }
    };

    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, context]);

  return useMemo(() => ({ errors, isValid }), [errors, isValid]);
};

export default useYupSchema;