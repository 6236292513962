import { ReactNode, useMemo, useState } from "react";
import styles from './Collapse.module.css';
import arrowUp from '../../../img/arrow-up.png';
import arrowDown from '../../../img/arrow-down.png';

export const Collapse = ({ title, content }: CollapseProps) => {
  const [collapsed, setCollapsed] = useState(true);

  const icon = useMemo(
    () => collapsed ? arrowDown : arrowUp,
    [collapsed],
  );

  const style = useMemo(
    () => collapsed ? styles.hidden : styles.content,
    [collapsed]
  );

  const toggle = () => {
    setCollapsed((previous) => !previous);
  };

  return (
    <div className={styles.collapsible}>
      <div className={styles.header} onClick={toggle}>
        <div className={styles.center}>
          {title}
        </div>
        <img className={styles.icon} src={icon} alt="icone" />
      </div>
      <div className={style}>
        {content}
      </div>
    </div >
  );
}

type CollapseProps = {
  title: string;
  content: ReactNode;
}