import { useContext } from 'react';
import styles from './FlipSwitcher.module.css';
import FormContext from '../../../store/form-context';

export const FlipSwitcher = ({ id, checked, onChange }: FlipSwitcherProps) => {
  const { categoria } = useContext(FormContext);
  const isCirurgiao = categoria === 'PLASTIC-SURGERY';

  return (
    <div className={styles.container}>
      <div className={styles['toggle-switch']}>
        <input
          id={id}
          name={id}
          type="checkbox"
          className={styles.checkbox}
          checked={checked}
          onChange={onChange}
          disabled={isCirurgiao}
        />
        <label className={styles.label} htmlFor={id}>
          <span className={styles.inner} />
          <span className={styles.switch} />
        </label>
      </div>
    </div>
  );
}

type FlipSwitcherProps = {
  id: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}