import styles from './Tooltip.module.css';
import icon from '../../../img/info-circle.png';
import iconSmall from '../../../img/info-circle-small.png';

export const Tooltip = ({
  description,
  size = 'normal'
}: { description: string; size?: 'normal' | 'small' }) => {
  const iconBySize = size === 'small' ? iconSmall : icon;

  return (
    <div className={styles.tooltip}>
      <img src={iconBySize} alt='icone de tooltip' />
      <span className={styles.tooltiptext}>{description}</span>
    </div>
  );
};