import { useContext, useMemo } from "react";
import FormContext from "../store/form-context";
import dayjs from "dayjs";
import { removeSpecialCharacters } from "../utils/remove-special-characters";
import { CotacaoRequest } from "../types/api-corsier";

const useCotacaoParams = (isSimulacao: boolean) => {
  const {
    cpf,
    crm,
    name,
    email,
    phone,
    endereco,
    numero,
    complemento,
    bairro,
    cidade,
    uf,
    cep,
    categoria,
    atividadesProcedimentos,
    retroatividade,
    reclamacao,
    expectativaSinistros,
    residente,
    cobertura,
    possiveisReclamantes,
    renovacao,
    apoliceBase64,
    seguradoraAtual,
    dataContratacaoRetroatividade,
    pep,
    nomePep,
    grauParentescoPep,
    medicoPerito,
    nomeSocial,
    possuiNomeSocial,
  } = useContext(FormContext);

  return useMemo<CotacaoRequest>(() => {
    const isRenovacao = renovacao === 'renovacao';
    const coberturas = isSimulacao ? [100000, 300000, 500000, 1000000] : [cobertura];

    const base = {
      modalidade: "MEDICA",
      dt_inicio_vigencia: dayjs().format('YYYY-MM-DD'),
      nome: name,
      telefone: removeSpecialCharacters(phone),
      categorias: [categoria],
      atividades_procedimentos: atividadesProcedimentos,
      residente,
      sinistros_24meses: reclamacao || 0,
      coberturas,
      renovacao: isRenovacao,
      perito_medico: medicoPerito,
    };

    const dadosRenovacao = isRenovacao ? {
      seguradora_anterior_apolice: apoliceBase64,
      seguradora_anterior_nome: seguradoraAtual,
      dt_retroatividade: dataContratacaoRetroatividade,
    } : {
      retroatividade,
    };

    const dadosContrato = isSimulacao ? {
      cpf: '[[CACHED]]',
      email: '[[CACHED]]',
    } : {
      cpf: removeSpecialCharacters(cpf),
      email,
      crm,
      logradouro: endereco,
      numero,
      complemento,
      bairro,
      cidade,
      uf,
      cep: removeSpecialCharacters(cep),
      expectativa_sinistros: expectativaSinistros === 'sim',
      expectativa_sinistros_reivindicacoes_terceiros: possiveisReclamantes,
      pep,
      pep_nome: nomePep,
      pep_parentesco: grauParentescoPep,
      permitir_nome_social_na_cotacao: possuiNomeSocial === 'sim',
      nome_social: nomeSocial,
    };

    return {
      ...base,
      ...dadosRenovacao,
      ...dadosContrato,
    };
  }, [
    cpf,
    crm,
    name,
    email,
    phone,
    endereco,
    numero,
    complemento,
    bairro,
    cidade,
    uf,
    cep,
    categoria,
    atividadesProcedimentos,
    retroatividade,
    reclamacao,
    expectativaSinistros,
    residente,
    cobertura,
    possiveisReclamantes,
    renovacao,
    apoliceBase64,
    seguradoraAtual,
    dataContratacaoRetroatividade,
    pep,
    nomePep,
    grauParentescoPep,
    medicoPerito,
    nomeSocial,
    possuiNomeSocial,
    isSimulacao,
  ]);
};

export default useCotacaoParams;
