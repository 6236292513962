import { useContext, useMemo } from 'react';
import { ErrorMessage } from '../../../../../error-message/ErrorMessage';
import styles from './ConfiguracaoPagamento.module.css';
import { OPTIONS_VENCIMENTO } from '../../../../../../utils/constants';
import FormContext from '../../../../../../store/form-context';
import Yup from '../../../../../../utils/yup';
import useYupSchema from '../../../../../../hooks/use-yup-schema';
import { formatNumber } from '../../../../../../utils/formatNumber';
import { isEmpty } from 'lodash';

const AlertBoleto = () => (
  <div className={styles.alertContainer}>
    <p className={styles.alert}>
      Atenção: o primeiro boleto tem vencimento para 7 dias a partir da data de contratação. Os demais terão vencimento de acordo com a data selecionada.
    </p>
  </div>
);

const validationSchema = Yup.object().shape({
  vencimento: Yup.number()
    .when('isBoleto', ([isBoleto], schema) =>
      isBoleto ? schema.moreThan(0, 'Por favor, selecione a data de vencimento').required("Por favor, selecione a data de vencimento") : schema
    ),
  idPagamento: Yup.string().required("Por favor, selecione o modo de parcelamento"),
});

export const ConfiguracaoPagamento = ({
  isErrorVisible,
}: { isErrorVisible: boolean }) => {
  const {
    metodoPagamento,
    cotacaoData,
    cotacaoDataComDesconto,
    vencimento,
    idPagamento,
    updateState
  } = useContext(FormContext);

  const { errors } = useYupSchema(
    {
      vencimento,
      idPagamento,
      metodoPagamento,
      isBoleto: metodoPagamento === "BOLETO",
    },
    validationSchema,
  );

  const cupomAplicado = !isEmpty(cotacaoDataComDesconto);

  const cotacao = useMemo(
    () => cupomAplicado
      ? cotacaoDataComDesconto?.cotacoes[0]
      : cotacaoData?.cotacoes[0],
    [cotacaoData, cupomAplicado, cotacaoDataComDesconto],
  );

  const dadosPagamento = useMemo(
    () => metodoPagamento === 'CARTAO'
      ? cotacao?.quotation.formasDePagto.CreditCard
      : cotacao?.quotation.formasDePagto.Ticket,
    [metodoPagamento, cotacao],
  );

  const optionsVencimento = useMemo(
    () => OPTIONS_VENCIMENTO.map(({ id, value, label }) => (
      <option
        key={value}
        id={id}
        value={value}
        selected={value === vencimento}
      >
        {label}
      </option>
    )), [vencimento]);

  const optionsParcelamento = useMemo(() => {
    if (!metodoPagamento) return;

    return dadosPagamento?.map((parcela: any) => {
      const formattedValue = formatNumber(parcela.valor);
      const label = `${parcela.parcelas} x de R$ ${formattedValue}`
      const text = parcela.parcelas === 1 ? '' : ' sem juros';

      return (
        <option
          key={parcela.identifier}
          id={parcela.identifier}
          value={parcela.identifier}
          selected={parcela.identifier === idPagamento}
        >
          {label}{text}
        </option>
      )
    });
  }, [idPagamento, metodoPagamento, dadosPagamento]);

  const onChangeVencimento: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    updateState({ vencimento: +event.target.value })
  };

  const onChangeParcelamento: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    updateState({ idPagamento: event.target.value })
  };

  return (
    <div className={styles.pagamentoContainer}>

      {metodoPagamento === 'BOLETO' && (
        <div>
          <label className={styles.pagamentoLabel} htmlFor="vencimento_select">Selecione a data de vencimento</label>
          <select id="vencimento_select" className={styles.pagamentoInput} name="vencimento" onChange={onChangeVencimento}>
            {optionsVencimento}
          </select>
          <AlertBoleto />
          <ErrorMessage message={errors.vencimento} isVisible={isErrorVisible && !!errors.vencimento} />
        </div>
      )}
      <div className={styles.marginTop}>
        <label className={styles.pagamentoLabel} htmlFor="parcelamento_select">Modo de parcelamento</label>
        <select id="parcelamento_select" className={styles.pagamentoInput} name="parcelamento" onChange={onChangeParcelamento}>
          <option
            key="selecione_parcela"
            id="selecione_parcela"
            value=""
          >
            Selecione a quantidade de parcelas
          </option>
          {optionsParcelamento}
        </select>
        <ErrorMessage message={errors.idPagamento} isVisible={isErrorVisible && !!errors.idPagamento} />
      </div>
    </div >
  )
};