import { useCallback, useState } from "react";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async ({ url, requestConfig, applyData }: SendRequest) => {
    if (!requestConfig || !url) return;

    setIsLoading(true);
    setError(null);

    const {
      method,
      headers,
      body
    } = requestConfig;

    try {
      const response = await fetch(
        url,
        {
          method: method || 'GET',
          headers: headers || {},
          body: body || null,
        }
      );

      if (!response.ok) {
        throw new Error('Request failed!');
      }

      if (applyData) {
        const data = await response.json();
        applyData(data);
      }
    } catch (err: any) {
      setError(err.message || 'Something went wrong!');
      throw Error(err.message || 'Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;

type SendRequest = {
  url?: RequestInfo;
  requestConfig?: RequestInit;
  applyData?: (data: any) => void;
}