import logo from '../../img/logo.png';
import styles from './Header.module.css';

export const Header = () => {
  return (
    <header className={styles.container}>
      <div className={styles.header}>
        <img src={logo} alt="Logo médico seguro" className={styles.logo} />
      </div>
    </header>
  );
};