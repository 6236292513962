import { Button } from "../../../../../UI/button/Button";
import styles from './CupomDesconto.module.css';
import close from '../../../../../../img/close.png';
import { useCallback, useContext, useState } from "react";
import FormContext from "../../../../../../store/form-context";
import { FullscreenLoader } from "../../../../../UI/fullscreen-loader/FullscreenLoader";
import { Alert } from "../../../../../UI/alert/Alert";
import ApiCrosier from "../../../../../../services/api-crosier";
import useCotacaoParams from "../../../../../../hooks/use-cotacao-params";
import { isEmpty } from "lodash";

export const CupomDesconto = () => {
  const { cupom, updateState } = useContext(FormContext);
  const [cupomAplicado, setCupomAplicado] = useState(() => !isEmpty(cupom));
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const cotacaoBody = useCotacaoParams(false);

  const cotar = useCallback(async () => {
    setIsLoading(true);

    try {
      const crosierService = new ApiCrosier();
      const bodyData = {
        ...cotacaoBody,
        cupom_desconto: cupom,
      };

      const response = await crosierService.cotar(bodyData);

      updateState({ cotacaoDataComDesconto: response });
    } catch (error: any) {
      console.warn(error);
      setShowError(true);
    } finally {
      window.scroll(0, 0);
      setIsLoading(false);
    }
  }, [cotacaoBody, cupom, updateState]);


  const onClickHandler = () => {
    setCupomAplicado(true);
    cotar();
  }

  const onRemove = () => {
    setCupomAplicado(false);
    updateState({ cupom: '', cotacaoDataComDesconto: undefined });
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateState({ cupom: event.target.value });
  }

  return (
    <div>
      <FullscreenLoader isLoading={isLoading} />
      <Alert
        message='Não foi possível aplicar o desconto. Por favor, verifique o cupom e tente novamente.'
        showAlert={showError}
        onClose={() => setShowError(false)}
      />
      <div className={styles.container}>
        <div className={styles.cupomContainer}>
          <label htmlFor="cupomDesconto" className={styles.label}>Cupom de desconto</label>
          <div className={styles.cupomInput}>
            <input
              id="cupomDesconto"
              type="text"
              className={styles.input}
              onChange={onChangeHandler}
              value={cupom}
              disabled={cupomAplicado}
            />
            {
              cupomAplicado && <img src={close} alt="remove button" onClick={onRemove} />
            }
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button label="Aplicar" type="light" onClick={onClickHandler} style={styles.button} small />
        </div>
      </div>
    </div>
  )
};