import { ReactElement } from 'react';
import styles from './RadioInput.module.css';

export const RadioInput = ({
  id,
  label,
  name,
  value,
  checked,
  disabled,
  inline = false,
  buttonStyles,
  textStyles,
  onChange,
}: PropTypes) => {
  const inputStyle = inline ? styles.inline : '';
  const labelStyle = inline ? styles.inlineLabel : '';

  return (
    <div className={styles.inputContainer}>
      <input
        id={id}
        className={`${styles.input} ${inputStyle} ${buttonStyles}`}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
      />
      <label
        htmlFor={`#${id}`}
        className={`${styles.inputLabel} ${labelStyle} ${textStyles}`}
        onClick={() => onChange(value)}
      >
        {label}
      </label>
    </div>
  );
}

type PropTypes = {
  id: string;
  label: string | ReactElement;
  name: string;
  value: string;
  checked: boolean;
  disabled?: boolean;
  inline?: boolean;
  buttonStyles?: string;
  textStyles?: string;
  onChange: (value: string) => void;
}