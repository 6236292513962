import styles from './Resumo.module.css';
import sharedStyles from '../../../../../SharedStyles.module.css';
import { useContext, useMemo } from 'react';
import FormContext from '../../../../../../store/form-context';
import { formatNumber } from '../../../../../../utils/formatNumber';
import { isEmpty } from 'lodash';

const NUMBER_OPTIONS = { style: 'currency', currency: 'BRL' };

export const Resumo = () => {
  const { cotacaoData, cotacaoDataComDesconto } = useContext(FormContext);

  const cupomAplicado = !isEmpty(cotacaoDataComDesconto);

  const cotacao = useMemo(
    () => cupomAplicado
      ? cotacaoDataComDesconto?.cotacoes[0]
      : cotacaoData?.cotacoes[0],
    [cupomAplicado, cotacaoData, cotacaoDataComDesconto],
  );

  const valorSemDescontoFormatted = useMemo(
    () => formatNumber(cotacaoData?.cotacoes[0].quotation.valor_total || 0, NUMBER_OPTIONS),
    [cotacaoData],
  );

  const valorFormatted = useMemo(() => {
    if (!cotacao?.quotation?.valor_total) return 0;

    return formatNumber(cotacao.quotation.valor_total, NUMBER_OPTIONS);
  }, [cotacao?.quotation?.valor_total]);

  return (
    <div>
      <div className={styles.marginDivider} >
        <hr />
      </div>
      <div className={styles.resumoContainer}>
        <div className={styles.resumoText}>
          <p>VALOR TOTAL DO SEGURO:</p>
          <div className={`${styles.resumoMargin} ${styles.resumoValue}`}>
            {cupomAplicado && (
              <p className={styles.resumoTextSecondary}>
                {valorSemDescontoFormatted}
              </p>
            )}
            <p className={sharedStyles.emphasis}>
              {valorFormatted}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.marginDivider} >
        <hr />
      </div>
    </div>
  );
}