import { useCallback, useContext, useLayoutEffect, useState } from "react";
import { NavigationButtons } from "../navigation-buttons/NavigationButtons";
import MultiStepsContext from "../../../../store/multi-steps-form-context";
import sharedStyles from '../../../SharedStyles.module.css';
import FormContext from "../../../../store/form-context";
import { CotacaoCard } from "./components/cotacao-card/CotacaoCard";
import styles from './Simulacao.module.css';
import { Collapse } from "../../../UI/collapse/Collapse";
import { DadosSimulacao } from "./components/dados-simulacao/DadosSimulacao";
import { Duvidas } from "./components/duvidas/Duvidas";
import { Protecao } from "./components/protecao/Protecao";
import { Diferenciais } from "./components/diferenciais/Diferenciais";
import { size } from "lodash";
import { FullscreenLoader } from "../../../UI/fullscreen-loader/FullscreenLoader";
import { Alert } from "../../../UI/alert/Alert";
import { CotacaoData } from "../../../../types/api-corsier";
import { SimulacaoData } from "../../../../types/global";
import ApiCrosier from "../../../../services/api-crosier";
import useCotacaoParams from "../../../../hooks/use-cotacao-params";

const COBERTURA_RECOMENDADA = 500000;

export const Simulacao = () => {
  const { goForward, goBack } = useContext(MultiStepsContext);
  const [dadosSimulacao, setDadosSimulacao] = useState<SimulacaoData[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const cotacaoBody = useCotacaoParams(false);

  const {
    name,
    cobertura,
    simulacaoData,
    renovacao,
    updateState,
  } = useContext(FormContext);

  const isRenovacao = renovacao === 'renovacao';

  const submitForm = useCallback(async () => {
    setIsLoading(true);

    try {
      const crosierService = new ApiCrosier();
      await crosierService.cotar(cotacaoBody);

      window.location.href = "https://medicoprotegido.sistemasulseguros.com.br/formulario/renovacaofinalizada/";
    } catch (error: any) {
      console.warn(error);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  }, [cotacaoBody]);

  const goNextStep = useCallback(() => {
    if (!isRenovacao) {
      goForward();
      return;
    }

    submitForm();
  }, [isRenovacao, submitForm, goForward]);

  const onSelectCotacao = (cotacao: number) => {
    updateState({ cobertura: cotacao });
    goNextStep();
  }

  useLayoutEffect(() => {
    if (!simulacaoData) return;

    const { cotacoes } = simulacaoData;

    const dados = cotacoes.map<SimulacaoData>(({ cobertura, quotation }: CotacaoData) => {
      const temp = quotation.formasDePagto.CreditCard;
      const index = size(quotation.formasDePagto.CreditCard) - 1;

      return {
        id: quotation.quotationIdentifier,
        coberturaCotacao: cobertura,
        valor: quotation.valor_total,
        parcelas: temp[index].parcelas,
        valorParcela: temp[index].valor,
      }
    });

    setDadosSimulacao(dados);
  }, [simulacaoData])

  return (
    <div className={sharedStyles.container}>
      <FullscreenLoader isLoading={isLoading} />
      <div className={styles.content}>
        <Alert
          message='Não foi possível salvar a cotação. Por favor, verifique seus dados e tente novamente.'
          showAlert={showError}
          onClose={() => setShowError(false)}
        />
        <div className={styles.section}>
          <p className={sharedStyles.text}>
            A simulação do
            <span className={sharedStyles.emphasis}> melhor seguro </span>
            para você
            <span className={sharedStyles.emphasis}> Dr(a). {name}</span>
          </p>
        </div>
        <div className={styles.cardsContainer}>
          {dadosSimulacao?.map(({ id, coberturaCotacao, valor, valorParcela, parcelas }) => (
            <CotacaoCard
              key={id}
              value={coberturaCotacao}
              valor={coberturaCotacao}
              valorParcela={valorParcela}
              valorAVista={valor}
              recommended={coberturaCotacao === COBERTURA_RECOMENDADA}
              selected={coberturaCotacao === cobertura}
              parcelas={parcelas}
              onSelect={onSelectCotacao}
            />
          ))}
        </div>
        <div className={styles.section}>
          <Collapse title="Detalhes da simulação" content={<DadosSimulacao />} />
        </div>
        <div className={styles.section}>
          <Collapse title="Qual limite segurado devo contratar?" content={<Duvidas />} />
        </div>
        <div className={styles.section}>
          <Protecao />
        </div>
        <div className={styles.section}>
          <Diferenciais />
        </div>
        <div className={sharedStyles.marginNavigationButtons}>
          <NavigationButtons onClickForward={goNextStep} onClickBack={goBack} />
        </div>
      </div>
    </div>
  )
};