import { HTMLProps } from 'react';
import styles from './Button.module.css';

const TYPES_STYLES = {
  light: styles.light,
  clear: styles.clear,
  default: styles.default,
};

export const Button = ({
  label,
  type = 'default',
  disabled = false,
  onClick,
  small = false,
  style,
}: ButtonProps) => {
  const buttonStyle = disabled ? styles.disabled : TYPES_STYLES[type];
  const buttonSize = small && styles.small;

  return (
    <button
      type="button"
      className={`${styles.button} ${buttonStyle} ${buttonSize} ${style}`}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

type ButtonProps = {
  label: string;
  type?: 'default' | 'light' | 'clear';
  disabled?: boolean;
  onClick: () => void;
  small?: boolean;
  style?: HTMLProps<HTMLElement>["className"]
}