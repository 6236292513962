import { Button } from "../../../UI/button/Button";
import styles from './NavigationButtons.module.css';

export const NavigationButtons = ({
  onClickForward,
  onClickBack,
}: NavigationButtonsProps) => (
  <div className={styles.buttonContainer}>
    <div className={`${styles.button} ${styles.marginRight}`}>
      <Button label="Voltar" type="light" onClick={onClickBack} />
    </div>
    <div className={styles.button}>
      <Button label="Avançar" onClick={onClickForward} />
    </div>
  </div>
);

type NavigationButtonsProps = {
  onClickForward: () => void;
  onClickBack: () => void;
}