import styles from './Cartao.module.css';
import sharedStyles from '../../../../../SharedStyles.module.css';
import { ErrorMessage } from '../../../../../error-message/ErrorMessage';
import ReactInputMask from 'react-input-mask';
import { Tooltip } from '../../../../../UI/tooltip/Tooltip';
import useYupSchema from '../../../../../../hooks/use-yup-schema';
import { useContext } from 'react';
import FormContext from '../../../../../../store/form-context';
import Yup from '../../../../../../utils/yup';

const validationSchema = Yup.object().shape({
  numeroCartao: Yup.string().required("Por favor, selecione o modo de parcelamento"),
  validade: Yup.string().required(),
  nomeTitular: Yup.string().required("Por favor, selecione o modo de parcelamento"),
  cvv: Yup.string()
    .matches(/^[0-9]{3}$/, 'CVV inválido')
    .required(),
});

export const Cartao = ({
  isErrorVisible,
}: PagamentoProps) => {
  const {
    numeroCartao,
    validade,
    nomeTitular,
    cvv,
    updateState
  } = useContext(FormContext);
  const { errors } = useYupSchema(
    {
      numeroCartao,
      validade,
      nomeTitular,
      cvv,
    },
    validationSchema,
  );

  const onChangeNumeroCartao: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    updateState({ numeroCartao: event.target.value })
  };

  const onChangeValidade: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    updateState({ validade: event.target.value })
  };

  const onChangeNomeTitular: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    updateState({ nomeTitular: event.target.value })
  };

  const onChangeCvv: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    updateState({ cvv: event.target.value })
  };

  return (
    <div className={styles.pagamentoContainer}>
      <div className={styles.pagamentoContainer}>
        <div className={`${sharedStyles.inline} ${styles.justifyBetween}`}>

          <div className={`${styles.flexColumn} ${styles.pagamentoLeft}`}>
            <label className={styles.pagamentoLabel} htmlFor="numero_cartao">Número do cartão</label>
            <ReactInputMask
              id="numero_cartao"
              mask="9999 9999 9999 9999"
              className={`${styles.pagamentoInput}`}
              type="text"
              placeholder="0000 0000 0000 0000"
              onChange={onChangeNumeroCartao}
            />
            <ErrorMessage message={errors.numeroCartao} isVisible={isErrorVisible && !!errors.numeroCartao} />
          </div>

          <div className={`${styles.flexColumn} ${styles.pagamentoRight}`}>
            <label className={styles.pagamentoLabel} htmlFor="validade">Validade</label>
            <ReactInputMask
              id="validade"
              mask="99/9999"
              className={`${styles.pagamentoInput} ${styles.textRight}`}
              type="text"
              placeholder="MM/AAAA"
              onChange={onChangeValidade}
            />
            <ErrorMessage message={errors.validade} isVisible={isErrorVisible && !!errors.validade} />
          </div>

        </div>

        <div className={`${sharedStyles.inline} ${styles.justifyBetween}`}>
          <div className={`${styles.flexColumn} ${styles.pagamentoLeft}`}>
            <label className={styles.pagamentoLabel} htmlFor="nome_titular">Nome do titular</label>
            <input
              id="nome_titular"
              className={styles.pagamentoInput}
              type="text"
              placeholder="Nome no cartão"
              onChange={onChangeNomeTitular}
            />
            <ErrorMessage message={errors.nomeTitular} isVisible={isErrorVisible && !!errors.nomeTitular} />
          </div>

          <div className={`${styles.flexColumn} ${styles.pagamentoRight}`}>
            <div className={styles.cvv}>
              <label className={styles.pagamentoLabel} htmlFor="cvv">CVV</label>
              <div>
                <Tooltip description="O número do CVV costuma ficar atrás do cartão de crédito e é composto por três ou quatro dígitos." size="small" />
              </div>
            </div>
            <ReactInputMask
              id="cvv"
              placeholder="000"
              mask="999"
              className={`${styles.pagamentoInput}
              ${styles.textRight}`}
              type="text"
              onChange={onChangeCvv}
            />
            <ErrorMessage message={errors.cvv} isVisible={isErrorVisible && !!errors.cvv} />
          </div>

        </div>
      </div >
    </div >
  )
};

type PagamentoProps = {
  isErrorVisible: boolean;
};