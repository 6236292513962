import { ReactNode } from 'react';
import styles from './Alert.module.css';
import { isString } from 'lodash';

export const Alert = ({ showAlert, message, onClose }: PropTypes) => {
  if (!showAlert) return <></>;

  const content = isString(message) ? <p>{message}</p> : message;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <button
          type="button"
          className={styles.closeButton}
          data-dismiss="alert"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className={styles.content}>
        {content}
      </div>
    </div >
  )
}

type PropTypes = {
  showAlert: boolean;
  message: string | ReactNode;
  onClose: () => void;
};