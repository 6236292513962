import { useCallback, useContext, useState } from 'react';
import sharedStyles from '../../../SharedStyles.module.css';
import { CheckboxInput } from '../../../UI/checkbox-input/CheckboxInput';
import { FlipSwitcher } from '../../../UI/flip-switcher/FlipSwitcher';
import { RadioInput } from '../../../UI/radio-input/RadioInput';
import { NavigationButtons } from '../navigation-buttons/NavigationButtons';
import MultiStepsContext from '../../../../store/multi-steps-form-context';
import { ESPECIALIDADES, PROCEDIMENTOS } from '../../../../utils/constants';
import FormContext from '../../../../store/form-context';
import styles from './Especialidade.module.css';
import { Tooltip } from '../../../UI/tooltip/Tooltip';
import Yup from '../../../../utils/yup';
import useYupSchema from '../../../../hooks/use-yup-schema';
import { ErrorMessage } from '../../../error-message/ErrorMessage';

const MAIN_CONTRATO_STEP = 7;
const CIRURGIAO_CONTRATO_STEP = 6;
const RENOVACAO_STEPS = 6;

export const EspecialidadeRadioButton = ({ disabled, onChange }: { disabled?: boolean, onChange?: () => void }) => {
  const { categoria, renovacao, updateState } = useContext(FormContext);
  const { currentStep, goToStep } = useContext(MultiStepsContext);

  const onChangeEspecialidade = useCallback((newEspecialidade: string) => {
    if (disabled) return;

    const isCirurgiao = newEspecialidade === 'PLASTIC-SURGERY';

    if (currentStep > 2) {
      const isRenovacao = renovacao === 'renovacao';
      let newStep = 0;

      if (isRenovacao) {
        newStep = RENOVACAO_STEPS;
      } else {
        newStep = isCirurgiao ? CIRURGIAO_CONTRATO_STEP : MAIN_CONTRATO_STEP;
      }

      goToStep(newStep);
    }

    if (isCirurgiao) updateState({ retroatividade: 0, residente: false });

    updateState({ categoria: newEspecialidade, atividadesProcedimentos: [] })
    if (onChange) onChange();
  }, [currentStep, renovacao, disabled, goToStep, updateState, onChange]);

  return (
    <div>
      {ESPECIALIDADES.map(({ id, label, name, value, tooltip }) => (
        <div className={sharedStyles.inline} key={id}>
          <div className={styles.optionsContainer}>
            <RadioInput
              id={id}
              label={label}
              name={name}
              value={value}
              checked={categoria === value}
              onChange={onChangeEspecialidade}
              disabled={disabled}
            />
          </div>
          <Tooltip description={tooltip} />
        </div>
      ))}
    </div>
  );
}

const validationSchema = Yup.object().shape({
  categoria: Yup.string()
    .required("Por favor, selecione a sua especialidade médica"),
});

export const Especialidade = () => {
  const { name, residente, atividadesProcedimentos, categoria, medicoPerito, updateState } = useContext(FormContext);
  const { goForward, goBack } = useContext(MultiStepsContext);
  const { isValid, errors } = useYupSchema({ categoria }, validationSchema);
  const [forwardPressed, setForwardPressed] = useState(false);

  const onChangeProcedimentos = (newProcedimento: string) => {
    const checked = atividadesProcedimentos.includes(newProcedimento);

    if (!checked) {
      const newValues = [...atividadesProcedimentos, newProcedimento];

      updateState({ atividadesProcedimentos: newValues });
    } else {
      const filteredValues = atividadesProcedimentos.filter(
        (item) => item !== newProcedimento
      );

      updateState({ atividadesProcedimentos: filteredValues })
    }
  };

  const onChangeResidente: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    updateState({ residente: event.target.checked })
  };

  const onChangeMedicoPerito: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    updateState({ medicoPerito: event.target.checked })
  };

  const validate = useCallback(() => {
    setForwardPressed(true);

    if (!isValid) return;

    goForward();
  }, [isValid, goForward]);

  const Procedimentos = () => {
    if (!categoria) return null;

    return (
      <>
        <div className={sharedStyles.marginTop}>
          <p className={sharedStyles.text}>
            Realiza alguns dos seguintes procedimentos e/ou atividades?
          </p>
        </div>
        <div className={sharedStyles.marginTop}>
          {
            PROCEDIMENTOS
              .filter(
                ({ especialidades }) => especialidades.includes(categoria)
              ).map(({ id, label, name, value, tooltip }) => (
                <div className={sharedStyles.inline} key={id} >
                  <div className={styles.optionsContainer}>
                    <CheckboxInput
                      id={id}
                      label={label}
                      name={name}
                      value={value}
                      checked={atividadesProcedimentos.includes(value)}
                      onChange={onChangeProcedimentos}
                    />
                  </div>
                  {tooltip && <Tooltip description={tooltip} />}
                </div>
              ))
          }
        </div>
      </>
    );
  };

  return (
    <div className={sharedStyles.container}>
      <div className={styles.content}>
        <p className={sharedStyles.text}>
          <span className={sharedStyles.emphasis}>Dr(a). {name}</span>, qual é sua
          <span className={sharedStyles.emphasis}> especialidade médica?</span>
        </p>

        <div className={sharedStyles.marginTop}>
          <EspecialidadeRadioButton />
          <ErrorMessage message={errors.categoria} isVisible={forwardPressed && !!errors.categoria} />
        </div>


        <Procedimentos />

        <div className={sharedStyles.marginTop}>
          <p className={sharedStyles.text}>
            Você é médico <span className={sharedStyles.emphasis}>residente</span>?
          </p>
        </div>

        <FlipSwitcher id="residente" checked={residente} onChange={onChangeResidente} />

        <div className={sharedStyles.marginTop}>
          <p className={sharedStyles.text}>
            Atua como <span className={sharedStyles.emphasis}>médico perito</span>?
          </p>
        </div>

        <FlipSwitcher id="medicoPerito" checked={medicoPerito} onChange={onChangeMedicoPerito} />

        <div className={sharedStyles.marginNavigationButtons}>
          <NavigationButtons onClickForward={validate} onClickBack={goBack} />
        </div>
      </div>
    </div >
  );
};