import sharedStyles from '../../../../../SharedStyles.module.css';
import styles from './Duvidas.module.css';

export const Duvidas = () => {
  return (
    <div className={sharedStyles.container}>
      <div className={styles.content}>
        <p>
          É essencial escolher um limite segurado adequado ao contratar um seguro de RC Profissional considerando que todos os custos relacionados a reclamações como: honorários advocatícios, perícias, custas judiciais, indenizações e acordos são retirados deste limite único. Reforçamos que este limite não é por processo e sim um limite único da apólice por vigência.
        </p>
        <p className={sharedStyles.marginTop}>
          Importante considerar também que um mesmo atendimento pode ter um desdobramento em até 3 processos (cível, administrativo e criminal) o que acarreta em custos elevados de defesa.
        </p>
        <p className={sharedStyles.marginTop}>
          Além disso, os processos judiciais costumam ser longos e nas condenações são aplicadas juros e correção monetária. Portanto, recomendamos a contratação de um limite adequado para garantir sua tranquilidade financeira diante de imprevistos da sua profissão.
        </p>
      </div>
    </div>
  )
}