import { useCallback, useContext, useState } from 'react';
import MultiStepsContext from '../../../../store/multi-steps-form-context';
import sharedStyles from '../../../SharedStyles.module.css';
import { RadioInput } from '../../../UI/radio-input/RadioInput';
import { NavigationButtons } from '../navigation-buttons/NavigationButtons';
import { RETROATIVIDADE, TOOLTIP_RETROATIVIDADE } from '../../../../utils/constants';
import FormContext from '../../../../store/form-context';
import styles from './Retroatividade.module.css';
import { ErrorMessage } from '../../../error-message/ErrorMessage';
import useYupSchema from '../../../../hooks/use-yup-schema';
import Yup from '../../../../utils/yup';
import { QuestionDescription } from '../../../UI/question-description/QuestionDescription';

const validationSchema = Yup.object().shape({
  retroatividade: Yup.string().required(),
});

export const Retroatividade = () => {
  const { name, retroatividade, updateState } = useContext(FormContext);
  const { goForward, goBack } = useContext(MultiStepsContext);
  const { isValid, errors } = useYupSchema({ retroatividade }, validationSchema);
  const [forwardPressed, setForwardPressed] = useState(false);

  const onChangeRetroatividade = (newRetroatividade: string) => {
    updateState({ retroatividade: +newRetroatividade })
  };

  const validate = useCallback(() => {
    setForwardPressed(true);

    if (!isValid) return;

    goForward();
  }, [isValid, goForward]);

  return (
    <div className={sharedStyles.container}>
      <div className={styles.content}>
        <div>
          <p className={sharedStyles.text}>
            <span className={sharedStyles.emphasis}>Dr(a). {name}</span>, quanto tempo de retroatividade você quer adicionar?
          </p>
          <QuestionDescription content={TOOLTIP_RETROATIVIDADE} />
        </div>
        <div className={sharedStyles.marginTop}>
          {RETROATIVIDADE.map(({ id, label, name, value }) => (
            <RadioInput
              key={id}
              id={id}
              label={label}
              name={name}
              value={value.toString()}
              checked={retroatividade === value}
              onChange={onChangeRetroatividade}
            />
          ))}
          <ErrorMessage message={errors.retroatividade} isVisible={forwardPressed && !!errors.retroatividade} />
        </div>

        <div className={sharedStyles.marginNavigationButtons}>
          <NavigationButtons onClickForward={validate} onClickBack={goBack} />
        </div>
      </div>
    </div>
  );
};