import styles from './TextInput.module.css';
import { MaskType, applyMask } from '../../../utils/mask';

export const TextInput = ({
  id,
  label,
  type,
  placeholder,
  value,
  theme,
  maskType,
  disabled = false,
  onChange: onChangeCallback,
  onBlur: onBlurCallback,
}: PropTypes) => {
  const inputStyle = theme === 'light' ? styles.inputLight : styles.inputDefault;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChangeCallback) return;

    if (maskType) {
      onChangeCallback(applyMask({ maskType, value: event.target.value }));
      return;
    }

    onChangeCallback(event.target.value)
  }

  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChangeCallback) return;

    const trimmedValue = event.target.value.trim();

    onChangeCallback(trimmedValue);
    if (onBlurCallback) onBlurCallback(trimmedValue);
  }

  return (
    <div className={styles.inputContainer}>
      {label && (
        <label
          htmlFor={`#${id}`}
          className={styles.inputLabel}
        >
          {label}
        </label>
      )}
      <input
        id={id}
        className={`${styles.input} ${inputStyle}`}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );
};

type PropTypes = {
  id: string;
  label?: string;
  type: React.HTMLInputTypeAttribute;
  placeholder?: string;
  value?: string;
  theme?: 'default' | 'light';
  maskType?: MaskType;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}