/* eslint-disable react/jsx-no-target-blank */
import { ReactNode, useCallback, useContext, useMemo, useState } from "react";
import MultiStepsContext from "../../../../store/multi-steps-form-context";
import FormContext from "../../../../store/form-context";
import sharedStyles from '../../../SharedStyles.module.css';
import { TextInput } from "../../../UI/text-input/TextInput";
import { EspecialidadeRadioButton } from "../especialidade/Especialidade";
import { RadioInput } from "../../../UI/radio-input/RadioInput";
import { COBERTURAS, EMAIL_REP_EXP, ESTADOS, EXPECTATIVA_OPTIONS, GRAU_PARENTESCO_PEP, PEP, PHONE_REG_EXP, POSSUI_NOME_SOCIAL_OPTIONS, RECLAMACOES, RESIDENTE_OPTIONS, RETROATIVIDADE, TOOLTIP_EXPECTATIVAS, TOOLTIP_FRANQUIA, TOOLTIP_NOME_SOCIAL, TOOLTIP_PEP, TOOLTIP_RECLAMACAO, TOOLTIP_RETROATIVIDADE } from "../../../../utils/constants";
import styles from './Contrato.module.css';
import { Select } from "../../../UI/select/Select";
import { Coberturas } from "./components/coberturas/Coberturas";
import { Tooltip } from "../../../UI/tooltip/Tooltip";
import { ErrorMessage } from "../../../error-message/ErrorMessage";
import Yup from "../../../../utils/yup";
import useYupSchema from "../../../../hooks/use-yup-schema";
import useHttp from "../../../../hooks/use-http";
import { removeSpecialCharacters } from "../../../../utils/remove-special-characters";
import { Button } from "../../../UI/button/Button";
import { FullscreenLoader } from "../../../UI/fullscreen-loader/FullscreenLoader";
import { Alert } from "../../../UI/alert/Alert";
import dayjs from "dayjs";
import { DateInput } from "../../../UI/date-input/DateInput";
import { validarCPF } from "../../../../utils/validador-cpf";
import useFranquia from "../../../../hooks/use-franquia";
import { QuestionDescription } from "../../../UI/question-description/QuestionDescription";
import { CheckboxInput } from "../../../UI/checkbox-input/CheckboxInput";
import ApiCrosier from "../../../../services/api-crosier";
import useCotacaoParams from "../../../../hooks/use-cotacao-params";
import { isEmpty, isEqual } from "lodash";

const Header = ({ name }: { name: string }) => (
  <div className={styles.textCenter}>
    <p className={sharedStyles.text}>
      Ótimo, <span className={sharedStyles.emphasis}> Dr(a). {name}</span>.
    </p>
    <p className={`${sharedStyles.text} ${sharedStyles.emphasis}`}>
      Já estamos na etapa final, confirme seus dados:
    </p>
  </div>
)

const LabelCoberturaSecuritaria = () => (
  <span className={styles.coberturaSecuritaria}>
    Fica entendido e acordado que não haverá cobertura securitária para qualquer tipo de fato já conhecido pelo segurado.
  </span>
)

const SinistralidadeAlert = () => {
  const { name } = useContext(FormContext);
  return (
    <div>
      <p>
        Dr(a). {name}, neste caso não conseguimos simular seu seguro por aqui. Pedimos a gentileza de aguardar o contato de um de nossos especialistas.
      </p>

      <p className={sharedStyles.marginTop}>
        Ou{' '}
        <a
          href="https://api.whatsapp.com/send?phone=554230289900&text=Seguro%20Medico%20Protegido"
          className={styles.link}
          target="_blank"
        >
          fale agora com um especialista.
        </a>
      </p>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Por favor, informe o seu nome"),
  email: Yup.string()
    .required("Por favor, informe o seu email")
    .matches(EMAIL_REP_EXP, "Por favor, informe um email válido"),
  phone: Yup.string()
    .required("Por favor, informe o seu telefone")
    .matches(PHONE_REG_EXP, 'Por favor, informe um telefone válido'),
  cpf: Yup.string()
    .test(
      'test-invalid-cpf',
      'CPF inválido',
      (cpf) => validarCPF(cpf || '')
    )
    .required("Por favor, informe o seu CPF"),
  dataNascimento: Yup.date()
    .typeError('Por favor, informe uma data válida')
    .max(new Date(), 'Não é possível incluir uma data futura')
    .required("Por favor, informe a sua data de nascimento"),
  cep: Yup.string().required("Por favor, informe o seu CEP"),
  endereco: Yup.string().required("Por favor, informe o seu endereço"),
  numero: Yup.string().required(),
  complemento: Yup.string(),
  bairro: Yup.string().required("Por favor, informe o seu bairro"),
  cidade: Yup.string().required("Por favor, informe a sua cidade"),
  crm: Yup.string().required("Por favor, informe o seu registro profissional"),
  expectativaSinistros: Yup.string().required(),
  uf: Yup.string().required(),
  pep: Yup.string().required(),
  nomePep: Yup.string()
    .when('pepRelacionamento', ([pepRelacionamento], schema) =>
      pepRelacionamento ? schema.required("Por favor, informe o nome do PEP") : schema
    ),
  grauParentescoPep: Yup.string()
    .when('pepRelacionamento', ([pepRelacionamento], schema) =>
      pepRelacionamento ? schema.required() : schema
    ),
  possiveisReclamantes: Yup.string()
    .when('temExpectativaProcessos', ([temExpectativaProcessos], schema) =>
      temExpectativaProcessos ? schema.required() : schema
    ),
  cienteCoberturaSecuritaria: Yup.boolean()
    .when('temExpectativaProcessos', ([temExpectativaProcessos], schema) =>
      temExpectativaProcessos ? schema.isTrue("Obrigatório") : schema
    ),
  nomeSocial: Yup.string()
    .when('nomeSocialObrigatorio', ([nomeSocialObrigatorio], schema) =>
      nomeSocialObrigatorio ? schema.required("Por favor, informe o nome social") : schema
    ),
});

export const Contrato = () => {
  const { goForward, goBack } = useContext(MultiStepsContext);
  const [forwardPressed, setForwardPressed] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const { isLoading, sendRequest } = useHttp();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<String | ReactNode>();
  const franquia = useFranquia();
  const [showFormError, setShowFormError] = useState(false);
  const simulacaoBody = useCotacaoParams(true);
  const cotacaoBody = useCotacaoParams(false);

  const {
    name,
    email,
    phone,
    cpf,
    dataNascimento,
    cep,
    endereco,
    numero,
    complemento,
    bairro,
    cidade,
    crm,
    cobertura,
    retroatividade,
    reclamacao,
    expectativaSinistros,
    residente,
    categoria,
    uf,
    possiveisReclamantes,
    cienteCoberturaSecuritaria,
    cotacaoData,
    cupom,
    pep,
    nomePep,
    grauParentescoPep,
    possuiNomeSocial,
    nomeSocial,
    updateState
  } = useContext(FormContext);

  const isCirurgiao = categoria === 'PLASTIC-SURGERY';
  const temExpectativaProcessos = expectativaSinistros === 'sim';
  const pepRelacionamento = pep === 'R';
  const nomeSocialObrigatorio = possuiNomeSocial === 'sim';

  const { isValid, errors } = useYupSchema(
    {
      temExpectativaProcessos,
      name,
      email,
      phone,
      cpf,
      dataNascimento,
      cep,
      endereco,
      numero,
      complemento,
      bairro,
      cidade,
      crm,
      expectativaSinistros,
      uf,
      possiveisReclamantes,
      cienteCoberturaSecuritaria,
      pep,
      pepRelacionamento,
      nomePep,
      grauParentescoPep,
      nomeSocialObrigatorio,
      nomeSocial,
    },
    validationSchema,
  );

  const optionsCobertura = useMemo(
    () => COBERTURAS.map(({ value, valorFormatado }) => {
      const selected = cobertura === value;

      return {
        id: `cb-opions-${value}`,
        value: value,
        selected,
        label: valorFormatado,
      };
    }),
    [cobertura],
  );

  const optionsRetroatividade = useMemo(
    () => RETROATIVIDADE.map(({ value, label, id }) => {
      const selected = retroatividade === value;

      return {
        id: `ret-opions-${id}`,
        value,
        selected,
        label,
      };
    }),
    [retroatividade],
  );

  const optionsReclamacao = useMemo(
    () => RECLAMACOES.map(({ value, label, id }) => {
      const selected = reclamacao === value;

      return {
        id: `ret-opions-${id}`,
        value,
        selected,
        label,
      };
    }),
    [reclamacao],
  );

  const optionsEstado = useMemo(
    () => ESTADOS.map(({ value, label, id }) => {
      const selected = uf === value;

      return {
        id,
        value,
        selected,
        label,
      };
    }),
    [uf],
  );

  const optionsPep = useMemo(
    () => PEP.map(({ value, label, id }) => {
      const selected = pep === value;

      return {
        id,
        value,
        selected,
        label,
      };
    }),
    [pep],
  );

  const optionsGrauParentesco = useMemo(
    () => GRAU_PARENTESCO_PEP.map(({ value, label, id }) => {
      const selected = pep === value;

      return {
        id,
        value,
        selected,
        label,
      };
    }),
    [pep],
  );

  const formattedDate = useMemo(() => {
    if (!dataNascimento) return;

    return dayjs(dataNascimento).format('DD/MM/YYYY');
  }, [dataNascimento]);

  const simular = useCallback(async (params: SimularParams) => {
    setIsRequestLoading(true);

    try {
      const crosierService = new ApiCrosier();
      const residente = !('residente' in params) ? simulacaoBody.residente : !!params.residente;
      const reclamacao = !('reclamacao' in params) ? simulacaoBody.sinistros_24meses : params.reclamacao || 0;
      const retroatividade = !('retroatividade' in params) ? simulacaoBody.retroatividade : params.retroatividade;

      const bodyData = {
        ...simulacaoBody,
        residente,
        sinistros_24meses: reclamacao,
        retroatividade,
      };
      const response = await crosierService.cotar(bodyData);

      updateState({ simulacaoData: response });
    } catch (error: any) {
      console.warn(error);
      setErrorMessage('Não foi possível simular a cotação. Por favor, verifique seus dados e tente novamente.');
      setShowError(true);
    } finally {
      setIsRequestLoading(false);
    };
  }, [simulacaoBody, updateState]);

  const cotar = useCallback(async () => {
    setIsRequestLoading(true);

    try {
      const crosierService = new ApiCrosier();
      const response = await crosierService.cotar(cotacaoBody);

      updateState({ cotacaoData: response });
      goForward();
    } catch (error: any) {
      console.warn(error);
      setErrorMessage('Não foi possível salvar a cotação. Por favor, verifique seus dados e tente novamente.');
      setShowError(true);
    } finally {
      window.scroll(0, 0);
      setIsRequestLoading(false);
    }
  }, [cotacaoBody, goForward, updateState]);

  const onChangeExpectativaOptions = (newExpectativaSinistros: string) => {
    updateState({
      expectativaSinistros: newExpectativaSinistros,
      possiveisReclamantes: '',
      cienteCoberturaSecuritaria: false,
    });
  };

  const onChangePossuiNomeSocial = (newPossuiNomeSocial: string) => {
    if (newPossuiNomeSocial === 'nao') {
      updateState({ nomeSocial: '' });
    }
    updateState({ possuiNomeSocial: newPossuiNomeSocial });
  };

  const onChangeResidenteOptions = (newResidente: string) => {
    if (isCirurgiao) return;

    const isResidente = newResidente === 'sim';
    updateState({ residente: isResidente });
    simular({ residente: isResidente });
  };

  const onChangeCobertura: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    updateState({ cobertura: +event.target.value });
  };

  const onChangeRetroatividade: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const retroatividade = +event.target.value;
    updateState({ retroatividade });
    simular({ retroatividade });
  };

  const onChangeReclamacao: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const reclamacao = +event.target.value;
    updateState({ reclamacao });

    if (reclamacao < 3) {
      simular({ reclamacao });
      return;
    }

    setErrorMessage(<SinistralidadeAlert />);
    setShowError(true);
    window.scroll(0, 0);
  };

  const onChangeEstado: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    updateState({ uf: event.target.value });
  };

  const onChangePep: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    updateState({ pep: event.target.value, nomePep: '', grauParentescoPep: '' });
  };

  const onChangeGrauParentesco: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    updateState({ grauParentescoPep: event.target.value });
  };

  const onChangeCienteCoberturaSecuritaria = (_newValue: string) => {
    const newState = !cienteCoberturaSecuritaria;
    updateState({ cienteCoberturaSecuritaria: newState });
  };

  const buscaEnderecoPorCep = async (newCep: string) => {
    const setEndereco = (enderecoData: any) => {
      updateState({
        endereco: enderecoData.logradouro,
        bairro: enderecoData.bairro,
        cidade: enderecoData.localidade,
        uf: enderecoData.uf,
      });
    };

    try {
      const cleanedCep = removeSpecialCharacters(newCep);
      await sendRequest({
        url: `https://viacep.com.br/ws/${cleanedCep}/json/`,
        requestConfig: {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        applyData: setEndereco,
      });
    } catch (error: any) {
      console.warn(error);
    }
  };

  const validate = useCallback(() => {
    setForwardPressed(true);

    if (!isValid) {
      setShowFormError(true);
      return;
    }

    if (isEqual(cotacaoData?.requestData, cotacaoBody)) {
      goForward();
      return;
    }

    if (!isEmpty(cupom)) {
      updateState({ cupom: '', cotacaoDataComDesconto: undefined });
    }

    cotar();
  }, [cotacaoBody, cotacaoData, isValid, cupom, goForward, cotar, updateState]);

  return (
    <div className={sharedStyles.container}>
      <FullscreenLoader isLoading={isLoading || isRequestLoading} />
      <div className={styles.content}>
        <Alert
          message={errorMessage}
          showAlert={showError}
          onClose={() => setShowError(false)}
        />
        <Header name={name} />
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <div className={styles.section}>
              <TextInput
                id="name"
                label="Nome completo:"
                type="text"
                placeholder="Olá Dr(a). como você se chama?"
                value={name}
                onChange={(value) => updateState({ name: value || '' })}
                theme="light"
              />
              <ErrorMessage message={errors.name} isVisible={forwardPressed && !!errors.name} />

              <div className={styles.biggerMarginBottom}>
                <div className={sharedStyles.inline}>
                  <p className={`${styles.inputLabel} ${styles.textCenter}`}>
                    Possui nome social:
                  </p>
                  <Tooltip description={TOOLTIP_NOME_SOCIAL} />
                </div>

                <div className={`${styles.inlineRadio} ${styles.marginTop}`}>
                  {POSSUI_NOME_SOCIAL_OPTIONS.map(({ id, label, name, value }) => (
                    <RadioInput
                      key={id}
                      id={id}
                      label={label}
                      name={name}
                      value={value}
                      checked={possuiNomeSocial === value}
                      onChange={onChangePossuiNomeSocial}
                      inline
                      buttonStyles={styles.radioButton}
                      textStyles={styles.radioText}
                    />
                  ))}
                </div>
              </div>
              {possuiNomeSocial === 'sim' && (
                <div>
                  <TextInput
                    id="nomeSocial"
                    label="Nome social:"
                    type="text"
                    placeholder="Nome social"
                    value={nomeSocial}
                    onChange={(value) => updateState({ nomeSocial: value || '' })}
                    theme="light"
                  />

                  <ErrorMessage message={errors.nomeSocial} isVisible={forwardPressed && !!errors.nomeSocial} />
                </div>
              )}

              <TextInput
                id="email"
                label="E-mail:"
                type="email"
                placeholder="Digite seu e-mail"
                value={email}
                onChange={(value) => updateState({ email: value })}
                theme="light"
              />
              <ErrorMessage message={errors.email} isVisible={forwardPressed && !!errors.email} />

              <TextInput
                id="celular"
                label="Celular"
                type="tel"
                placeholder="Digite o número do seu celular"
                value={phone}
                maskType="phone"
                onChange={(value) => updateState({ phone: value })}
                theme="light"
              />
              <ErrorMessage message={errors.phone} isVisible={forwardPressed && !!errors.phone} />
            </div>

            <div className={styles.section}>
              <p className={styles.sectionTitle}>
                Dados
              </p>
              <div className={styles.biggerMarginBottom}>
                <div className={sharedStyles.inline}>
                  <p className={`${styles.inputLabel} ${styles.textCenter}`}>
                    É pessoa politicamente exposta?
                  </p>
                  <Tooltip description={TOOLTIP_PEP} />
                </div>
                <Select
                  name="select-pep"
                  options={optionsPep}
                  onChange={onChangePep}
                />
                <div className={styles.marginTop}>
                  <ErrorMessage message={errors.pep} isVisible={forwardPressed && !!errors.pep} />
                </div>

                {
                  pepRelacionamento && (
                    <div className={sharedStyles.marginTop}>
                      <div className={sharedStyles.marginTop}>
                        <TextInput
                          id="nome-pep"
                          label="Nome do PEP"
                          type="text"
                          placeholder="Digite o nome do PEP"
                          value={nomePep}
                          onChange={(value) => updateState({ nomePep: value })}
                          theme="light"
                        />
                        <ErrorMessage message={errors.nomePep} isVisible={forwardPressed && !!errors.nomePep} />
                      </div>

                      <div className={styles.marginBottom}>
                        <p className={styles.inputLabel}>
                          Grau de Parentesco
                        </p>
                        <Select
                          name="select-grau-parentesco-pep"
                          options={optionsGrauParentesco}
                          onChange={onChangeGrauParentesco}
                        />
                        <div className={styles.marginTop}>
                          <ErrorMessage message={errors.grauParentescoPep} isVisible={forwardPressed && !!errors.grauParentescoPep} />
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>

              <TextInput
                id="cpf"
                label="CPF"
                type="text"
                placeholder="000.000.000-00"
                value={cpf}
                onChange={(value) => updateState({ cpf: value })}
                theme="light"
                maskType="cpf"
              />
              <ErrorMessage message={errors.cpf} isVisible={forwardPressed && !!errors.cpf} />

              <DateInput
                id="dataNascimento"
                label="Data de Nascimento"
                type="text"
                placeholder="DD/MM/AAAA"
                maskType="date"
                value={formattedDate}
                onChange={(value) =>
                  updateState({ dataNascimento: dayjs(value, 'DD/MM/YYYY', true).toDate() })
                }
                theme="light"
              />
              <ErrorMessage message={errors.dataNascimento} isVisible={forwardPressed && !!errors.dataNascimento} />

              <TextInput
                id="cep"
                label="CEP"
                type="text"
                placeholder="00000-000"
                value={cep}
                onChange={(value) => updateState({ cep: value })}
                theme="light"
                maskType="cep"
                onBlur={(newCep: string) => buscaEnderecoPorCep(newCep)}
              />
              <ErrorMessage message={errors.cep} isVisible={forwardPressed && !!errors.cep} />

              <TextInput
                id="endereco"
                label="Endereço"
                type="text"
                placeholder="Digite o seu endereço"
                value={endereco}
                onChange={(value) => updateState({ endereco: value })}
                theme="light"
              />
              <ErrorMessage message={errors.endereco} isVisible={forwardPressed && !!errors.endereco} />

              <div className={styles.inline}>
                <div className={styles.inlineLeft}>
                  <TextInput
                    id="numero"
                    label="Número"
                    type="number"
                    placeholder="0000"
                    value={numero}
                    onChange={(value) => updateState({ numero: value })}
                    theme="light"
                  />
                  <ErrorMessage message={errors.numero} isVisible={forwardPressed && !!errors.numero} />
                </div>

                <div className={styles.inlineRight}>
                  <TextInput
                    id="complemento"
                    label="Complemento"
                    type="text"
                    value={complemento}
                    placeholder="Informe o complemento"
                    onChange={(value) => updateState({ complemento: value })}
                    theme="light"
                  />
                </div>
              </div>

              <div className={styles.marginBottom}>
                <p className={styles.inputLabel}>
                  Estado
                </p>
                <Select
                  name="select-estado"
                  options={optionsEstado}
                  onChange={onChangeEstado}
                />
                <div className={styles.marginTop}>
                  <ErrorMessage message={errors.uf} isVisible={forwardPressed && !!errors.uf} />
                </div>
              </div>

              <TextInput
                id="bairro"
                label="Bairro"
                type="text"
                value={bairro}
                placeholder="Informe o seu bairro"
                onChange={(value) => updateState({ bairro: value })}
                theme="light"
              />
              <ErrorMessage message={errors.bairro} isVisible={forwardPressed && !!errors.bairro} />

              <TextInput
                id="cidade"
                label="Cidade"
                type="text"
                value={cidade}
                placeholder="Informe a sua cidade"
                onChange={(value) => updateState({ cidade: value })}
                theme="light"
              />
              <ErrorMessage message={errors.cidade} isVisible={forwardPressed && !!errors.cidade} />

              <TextInput
                id="crm"
                label="Registro Profissional"
                type="text"
                placeholder="0000000000"
                value={crm}
                onChange={(value) => updateState({ crm: value })}
                theme="light"
              />
              <ErrorMessage message={errors.crm} isVisible={forwardPressed && !!errors.crm} />
            </div>

            <div className={styles.section}>
              <p className={styles.sectionTitle}>
                Especialidade
              </p>
              <EspecialidadeRadioButton disabled />
            </div>

            <div className={styles.formBottom}>
              <div className={styles.section}>
                <p className={styles.sectionTitle}>
                  Seguro
                </p>
                <p className={sharedStyles.emphasis}>
                  Possui alguma expectativa de processo ou reclamações?
                </p>
                <QuestionDescription content={TOOLTIP_EXPECTATIVAS} />

                <div className={`${styles.inlineRadio} ${styles.marginTop}`}>
                  {EXPECTATIVA_OPTIONS.map(({ id, label, name, value }) => (
                    <RadioInput
                      key={id}
                      id={id}
                      label={label}
                      name={name}
                      value={value}
                      checked={expectativaSinistros === value}
                      onChange={onChangeExpectativaOptions}
                      inline
                    />
                  ))}

                </div>
                <ErrorMessage message={errors.expectativaSinistros} isVisible={forwardPressed && !!errors.expectativaSinistros} />

                {
                  temExpectativaProcessos && (
                    <div className={sharedStyles.marginTop}>
                      <p className={sharedStyles.emphasis}>
                        Descreva aqui os possíveis reclamantes
                      </p>

                      <div className={sharedStyles.marginTop}>
                        <TextInput
                          id="desc-possiveis-rec"
                          type="text"
                          placeholder="Resposta obrigatória"
                          value={possiveisReclamantes}
                          onChange={(value) => updateState({ possiveisReclamantes: value })}
                          theme="light"
                        />
                        <ErrorMessage message={errors.possiveisReclamantes} isVisible={forwardPressed && !!errors.possiveisReclamantes} />
                      </div>
                      <div className={sharedStyles.marginTop}>
                        <CheckboxInput
                          key="checkbox-cobertura-securitaria"
                          id="termo"
                          label={<LabelCoberturaSecuritaria />}
                          name="coberturaSecuritaria"
                          value="coberturaSecuritaria"
                          checked={cienteCoberturaSecuritaria}
                          onChange={onChangeCienteCoberturaSecuritaria}
                        />
                        <ErrorMessage message={errors.cienteCoberturaSecuritaria} isVisible={forwardPressed && !!errors.cienteCoberturaSecuritaria} />
                      </div>
                    </div>
                  )
                }

                <p className={`${sharedStyles.emphasis} ${sharedStyles.marginTop}`}>
                  Médico residente
                </p>

                <div className={`${styles.inlineRadio} ${styles.marginTop}`}>
                  {RESIDENTE_OPTIONS.map(({ id, label, name, value }) => (
                    <RadioInput
                      key={id}
                      id={id}
                      label={label}
                      name={name}
                      value={value}
                      checked={(residente && value === 'sim') || (!residente && value === 'nao')}
                      onChange={onChangeResidenteOptions}
                      disabled={isCirurgiao}
                      inline
                    />
                  ))}
                </div>

                <p className={`${sharedStyles.emphasis} ${sharedStyles.marginTop}`}>
                  Cobertura
                </p>

                <Select
                  name="select-cobertura"
                  options={optionsCobertura}
                  onChange={onChangeCobertura}
                />

                <div className={`${sharedStyles.inline} ${sharedStyles.marginTop}`}>
                  <p className={sharedStyles.emphasis}>
                    Retroatividade
                  </p>
                  <Tooltip description={TOOLTIP_RETROATIVIDADE} />
                </div>

                <Select
                  name="select-cobertura"
                  options={optionsRetroatividade}
                  onChange={onChangeRetroatividade}
                  disabled={isCirurgiao}
                />
                <div className={`${sharedStyles.inline} ${sharedStyles.marginTop}`}>
                  <p className={sharedStyles.emphasis}>
                    Sinistralidade nos últimos 24 meses
                  </p>
                  <Tooltip description={TOOLTIP_RECLAMACAO} />
                </div>

                <Select
                  name="select-reclamacao"
                  options={optionsReclamacao}
                  onChange={onChangeReclamacao}
                />

                <div className={`${sharedStyles.inline} ${sharedStyles.marginTop}`}>
                  <p className={sharedStyles.emphasis}>
                    Franquia
                  </p>
                  <Tooltip description={TOOLTIP_FRANQUIA} />
                </div>

                <TextInput
                  id="franquia"
                  type="text"
                  value={franquia}
                  theme="light"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <Coberturas showingError={showError || (forwardPressed && showFormError)} />
          </div>
        </div>

        <div className={styles.errorContainer}>
          <div>
            <Alert
              showAlert={forwardPressed && showFormError}
              onClose={() => setShowFormError(false)}
              message="Verifique se todos os campos estão preenchidos." />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <div className={`${styles.button} ${styles.marginRight}`}>
            <Button
              label="Voltar"
              type="light"
              onClick={goBack}
              disabled={isLoading}
            />
          </div>
          <div className={styles.button}>
            <Button
              label="Avançar"
              onClick={validate}
              disabled={isLoading || reclamacao === 3}
            />
          </div>
        </div>
      </div>
    </div >
  )
};

type SimularParams = {
  residente?: boolean;
  reclamacao?: number;
  retroatividade?: number;
}