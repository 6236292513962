import { useContext, useMemo } from "react";
import { FRANQUIAS } from "../utils/constants";
import FormContext from "../store/form-context";

const useFranquia = () => {
  const { categoria } = useContext(FormContext);

  return useMemo(
    () => FRANQUIAS.find(
      ({ especialidade }: any) => especialidade === categoria
    )?.franquia,
    [categoria],
  );
};

export default useFranquia;
