import { useContext, useRef } from 'react';
import styles from './FileInput.module.css';
import FormContext from '../../../store/form-context';
import download from '../../../img/download.png';

const convertToBase64 = (file: File) => new Promise<string | undefined>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    resolve(reader.result?.toString());
  };
  reader.onerror = reject;
});

export const FileInput = () => {
  const { apoliceFile, updateState } = useContext(FormContext);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!inputRef.current) return;

    inputRef.current.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const fileObj = event.target.files && event.target.files[0];

    if (!fileObj) {
      return;
    }

    const fileBase64 = await convertToBase64(fileObj);

    updateState({
      apoliceFile: fileObj.name,
      apoliceBase64: fileBase64,
    });
  };

  const dropHandler = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const fileObj = event.dataTransfer.items[0].getAsFile();

    if (!fileObj) {
      return;
    }

    const fileBase64 = await convertToBase64(fileObj);

    updateState({
      apoliceFile: fileObj.name,
      apoliceBase64: fileBase64,
    });
  };

  const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  return (

    <div className={styles.fileContainer}>
      <div className={styles.fileContent}>
        <div className={styles.fileInput}>
          <div className={`${styles.small} ${styles.dropZone}`}
            onDrop={dropHandler}
            onDragOver={dragOverHandler}>
            <img src={download} alt="download icon" />
            <p className={styles.dragText}>Arraste o arquivo aqui</p>
          </div>
          <input
            ref={inputRef}
            type="file"
            id="upload-file"
            name="upload"
            className={styles.hidden}
            onChange={handleFileChange}
            accept="application/pdf, image/jpeg"
          />
          <button className={styles.button} onClick={handleClick}>ou Selecione o arquivo</button>

        </div>
        <div className={styles.fileName}>
          <p>Arquivo: {apoliceFile}</p>
        </div>
      </div>
    </div>
  )
}