import { useContext, useMemo } from 'react';
import FormContext from '../../../../../../store/form-context';
import sharedStyles from '../../../../../SharedStyles.module.css';
import { COBERTURAS, ESPECIALIDADES, PROCEDIMENTOS, RECLAMACOES, RETROATIVIDADE } from '../../../../../../utils/constants';
import styles from './DadosSimulacao.module.css';
import useFranquia from '../../../../../../hooks/use-franquia';
import dayjs from 'dayjs';

export const DadosSimulacao = () => {
  const franquia = useFranquia();
  const {
    categoria,
    atividadesProcedimentos,
    cobertura,
    retroatividade,
    residente,
    reclamacao,
    renovacao,
    dataContratacaoRetroatividade,
  } = useContext(FormContext);

  const especialidadeDescricao = useMemo(
    () => {
      const especialidade = ESPECIALIDADES.find(({ value }) => value === categoria)?.label;
      const procedimentos = PROCEDIMENTOS
        .filter(({ value }) => atividadesProcedimentos.includes(value));

      if (!procedimentos.length) return especialidade;

      const procedimentosFormatted = procedimentos.map(({ label }) => label).join(', ');

      return `${especialidade} (${procedimentosFormatted})`;
    }, [categoria, atividadesProcedimentos]);

  const coberturaDescricao = useMemo(
    () => COBERTURAS.find(({ value }) => value === cobertura)?.valorFormatado,
    [cobertura],
  );

  const reclamacaoDescricao = useMemo(
    () => RECLAMACOES.find(({ value }) => value === reclamacao)?.label,
    [reclamacao],
  );

  const retroatividadeDescricao = useMemo(
    () => RETROATIVIDADE.find(({ value }) => value === retroatividade)?.label,
    [retroatividade],
  );

  const retroatividadeFormatted = useMemo(() => {
    if (!dataContratacaoRetroatividade && !retroatividadeDescricao) return '';

    const isRenovacao = renovacao === 'renovacao';
    return isRenovacao ? dayjs(dataContratacaoRetroatividade).format('DD/MM/YYYY') : retroatividadeDescricao;
  }, [dataContratacaoRetroatividade, retroatividadeDescricao, renovacao]);

  const residenteDescricao = residente ? 'Sim' : 'Não';

  return (
    <div className={sharedStyles.container}>
      <div className={styles.content}>
        <p className={sharedStyles.emphasis}>Especialidade e procedimentos</p>
        <p className={styles.description}>{especialidadeDescricao}</p>
        <hr className={styles.divider} />

        <p className={sharedStyles.emphasis}>Valor de cobertura</p>
        <p className={styles.description}>{coberturaDescricao}</p>
        <hr />

        <p className={sharedStyles.emphasis}>Retroatividade</p>
        <p className={styles.description}>{retroatividadeFormatted}</p>
        <hr />

        <p className={sharedStyles.emphasis}>Sininstralidade nos últimos 24 meses</p>
        <p className={styles.description}>{reclamacaoDescricao}</p>
        <hr />

        <p className={sharedStyles.emphasis}>Médico residente</p>
        <p className={styles.description}>{residenteDescricao}</p>
        <hr />

        <p className={sharedStyles.emphasis}>Franquia</p>
        <p className={styles.description}>{franquia}</p>
      </div>
    </div>
  )
};