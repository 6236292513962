import { PROTECAO_COBERTURAS } from "../../../../../../utils/constants";
import icon from '../../../../../../img/tick-circle.png';
import styles from './Protecao.module.css';

const ProtecaoItem = ({ label }: { label: string }) => (
  <div className={styles.item}>
    <img className={styles.icon} src={icon} alt="icone" />
    <p>{label}</p>
  </div>
);

export const Protecao = () => {
  const { left, right } = PROTECAO_COBERTURAS;

  return (
    <fieldset className={styles.fieldsetContainer}>
      <legend className={styles.legend}>
        Confira as coberturas:
      </legend>
      <div className={`${styles.container} ${styles.medium}`}>
        <div className={styles.content}>
          {
            left.map((item) => <ProtecaoItem key={item} label={item} />)
          }
        </div>
        <div className={styles.content}>
          {
            right.map((item) => <ProtecaoItem key={item} label={item} />)
          }
        </div>
      </div>
      <div className={`${styles.container} ${styles.small}`}>
        <div className={styles.content}>
          {
            left.concat(right).map((item) => <ProtecaoItem key={item} label={item} />)
          }
        </div>
      </div>
    </fieldset>
  );
};