/* eslint-disable react/jsx-no-target-blank */
import { useCallback, useContext, useState } from 'react';
import sharedStyles from '../../../SharedStyles.module.css';
import { RadioInput } from '../../../UI/radio-input/RadioInput';
import MultiStepsContext from '../../../../store/multi-steps-form-context';
import { RECLAMACOES, TOOLTIP_RECLAMACAO } from '../../../../utils/constants';
import FormContext from '../../../../store/form-context';
import styles from './Processo.module.css';
import { ErrorMessage } from '../../../error-message/ErrorMessage';
import useYupSchema from '../../../../hooks/use-yup-schema';
import Yup from '../../../../utils/yup';
import { Button } from '../../../UI/button/Button';
import { QuestionDescription } from '../../../UI/question-description/QuestionDescription';

const BlockingMessage = () => {
  const { name } = useContext(FormContext);
  return (
    <div className={`${styles.messageContainer} ${sharedStyles.marginTop}`}>
      <p>
        Dr(a). {name}, neste caso não conseguimos simular seu seguro por aqui.
      </p>
      <p>
        Pedimos a gentileza de aguardar o contato de um de nossos especialistas.
      </p>
      <p className={sharedStyles.marginTop}>
        Ou{' '}
        <a
          href="https://api.whatsapp.com/send?phone=554230289900&text=Seguro%20Medico%20Protegido"
          className={styles.link}
          target="_blank"
        >
          fale agora com um especialista.
        </a>
      </p>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  reclamacao: Yup.string().required(),
});

export const Processo = () => {
  const { reclamacao, block, updateState } = useContext(FormContext);
  const { goForward, goBack } = useContext(MultiStepsContext);
  const { isValid, errors } = useYupSchema({ reclamacao }, validationSchema);
  const [forwardPressed, setForwardPressed] = useState(false);

  const onChangeReclamacao = (newReclamacao: string) => {
    updateState({
      block: newReclamacao === '3',
      reclamacao: +newReclamacao
    });
  };

  const validate = useCallback(() => {
    setForwardPressed(true);

    if (!isValid) return;

    goForward();
  }, [isValid, goForward]);

  return (
    <div className={sharedStyles.container}>
      <div className={styles.content}>
        <div>
          <p className={sharedStyles.text}>
            Você recebeu algum processo/reclamação nos últimos 2 anos?
          </p>
          <QuestionDescription content={TOOLTIP_RECLAMACAO} />
        </div>

        <div className={sharedStyles.marginTop}>
          {RECLAMACOES.map(({ id, label, name, value }) => (
            <RadioInput
              key={id}
              id={id}
              label={label}
              name={name}
              value={value.toString()}
              checked={reclamacao === value}
              onChange={onChangeReclamacao}
            />
          ))}
          <ErrorMessage message={errors.reclamacao} isVisible={forwardPressed && !!errors.reclamacao} />

        </div>
        {block && <BlockingMessage />}
        <div className={`${sharedStyles.marginNavigationButtons} ${styles.buttonContainer}`}>
          <div className={`${styles.button} ${styles.marginRight}`}>
            <Button label="Voltar" type="light" onClick={goBack} />
          </div>
          <div className={styles.button}>
            <Button label="Avançar" onClick={validate} disabled={block} />
          </div>
        </div>
      </div>
    </div>
  );
};