import './App.css';
import { Header } from './components/header/Header';
import { Form } from './components/form/Form';
import { FormContextProvider } from './store/form-context';
import './configuration';

function App() {

  return (
    <div>
      <Header />
      <FormContextProvider>
        <Form />
      </FormContextProvider>
    </div>
  );
}

export default App;
