import { useCallback, useContext, useState } from 'react';
import sharedStyles from '../../../SharedStyles.module.css';
import { NavigationButtons } from '../navigation-buttons/NavigationButtons';
import styles from './Cpf.module.css';
import MultiStepsContext from '../../../../store/multi-steps-form-context';
import { TextInput } from '../../../UI/text-input/TextInput';
import { ErrorMessage } from '../../../error-message/ErrorMessage';
import FormContext from '../../../../store/form-context';
import useYupSchema from '../../../../hooks/use-yup-schema';
import Yup from '../../../../utils/yup';
import { FullscreenLoader } from '../../../UI/fullscreen-loader/FullscreenLoader';
import { Alert } from '../../../UI/alert/Alert';
import { validarCPF } from '../../../../utils/validador-cpf';
import ApiCrosier from '../../../../services/api-crosier';
import useCotacaoParams from '../../../../hooks/use-cotacao-params';

const validationSchema = Yup.object().shape({
  cpf: Yup.string()
    .test(
      'test-invalid-cpf',
      'CPF inválido',
      (cpf) => validarCPF(cpf || '')
    )
    .required("Por favor, informe o seu CPF"),
});

export const Cpf = () => {
  const { cpf, updateState } = useContext(FormContext);
  const { goForward, goBack } = useContext(MultiStepsContext);
  const { isValid, errors } = useYupSchema({ cpf }, validationSchema);
  const [forwardPressed, setForwardPressed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const cotacaoBody = useCotacaoParams(true);

  const cotar = useCallback(async () => {
    setIsLoading(true);

    try {
      const crosierService = new ApiCrosier();
      const response = await crosierService.cotar(cotacaoBody);

      updateState({ simulacaoData: response });
      goForward();
    } catch (error: any) {
      console.warn(error);
      setShowError(true);
    } finally {
      setIsLoading(false);
    };
  }, [cotacaoBody, goForward, updateState]);

  const validate = useCallback(() => {
    setForwardPressed(true);

    if (!isValid) return;

    cotar()
  }, [isValid, cotar]);

  return (
    <div className={sharedStyles.container}>
      <FullscreenLoader isLoading={isLoading} />
      <div className={styles.content}>
        <Alert
          message='Não foi possível realizar a simulação. Por favor, tente novamente.'
          showAlert={showError}
          onClose={() => setShowError(false)}
        />
        <p className={sharedStyles.text}>
          Estamos quase finalizando sua simulação.
        </p>
        <p className={sharedStyles.text}>
          Para finalizar, por favor informe o número do seu <span className={sharedStyles.emphasis}>CPF</span>:
        </p>
        <div className={styles.inputContainer}>
          <div className={sharedStyles.marginTop}>
            <TextInput
              id="cpf"
              label="CPF"
              type="text"
              placeholder="Digite o número do seu CPF"
              value={cpf}
              onChange={(value) => updateState({ cpf: value })}
              maskType="cpf"
            />
            <ErrorMessage message={errors.cpf} isVisible={forwardPressed && !!errors.cpf} />
          </div>
        </div>

        <div className={sharedStyles.marginNavigationButtons}>
          <NavigationButtons onClickForward={validate} onClickBack={goBack} />
        </div>
      </div>
    </div>
  );
}