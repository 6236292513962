import { createContext } from "react";

const MultiStepsContext = createContext<StepsContext>({
  currentStep: 0,
  isFirstStep: true,
  isLastStep: false,
  goForward: () => { },
  goBack: () => { },
  goToStep: () => { },
});

export default MultiStepsContext;

interface StepsContext {
  currentStep: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  goForward: () => void;
  goBack: () => void;
  goToStep: (step: number) => void;
}
