import styles from './ErrorMessage.module.css';

export const ErrorMessage = ({ message, isVisible }: PropTypes) => {
  if (!isVisible) return <></>;

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        {message}
      </p>
    </div>
  )
}

type PropTypes = {
  message?: string;
  isVisible: boolean;
};