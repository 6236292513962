import sharedStyles from '../../../SharedStyles.module.css';
import { useCallback, useContext, useMemo, useState } from "react";
import MultiStepsContext from "../../../../store/multi-steps-form-context";
import { NavigationButtons } from "../navigation-buttons/NavigationButtons";
import styles from './RetroatividadeRenovacao.module.css';
import FormContext from '../../../../store/form-context';
import { TOOLTIP_RETROATIVIDADE_RETROATIVIDADE } from '../../../../utils/constants';
import { CheckboxInput } from '../../../UI/checkbox-input/CheckboxInput';
import { ErrorMessage } from '../../../error-message/ErrorMessage';
import Yup from '../../../../utils/yup';
import useYupSchema from '../../../../hooks/use-yup-schema';
import { DateInput } from '../../../UI/date-input/DateInput';
import dayjs from 'dayjs';
import { QuestionDescription } from '../../../UI/question-description/QuestionDescription';

const validationSchema = Yup.object().shape({
  dataContratacaoRetroatividade: Yup.date()
    .typeError('Por favor, informe uma data válida')
    .max(new Date(), 'Não é possível incluir uma data futura')
    .required("Por favor, informe a data da 1º contratação"),
});

export const RetroatividadeRenovacao = () => {
  const {
    name,
    dataContratacaoRetroatividade,
    cienteTermoRetroatividade,
    updateState
  } = useContext(FormContext);
  const { goForward, goBack } = useContext(MultiStepsContext);
  const [forwardPressed, setForwardPressed] = useState(false);
  const { isValid, errors } = useYupSchema(
    { dataContratacaoRetroatividade },
    validationSchema,
  );

  const formattedDateContratacao = useMemo(() => {
    if (!dataContratacaoRetroatividade) return;

    return dayjs(dataContratacaoRetroatividade).format('DD/MM/YYYY');
  }, [dataContratacaoRetroatividade]);

  const onChangeCienciaTermo = (_newValue: string) => {
    const newState = !cienteTermoRetroatividade;
    updateState({ cienteTermoRetroatividade: newState });
  };

  const validate = useCallback(() => {
    setForwardPressed(true);

    if (!isValid || !cienteTermoRetroatividade) return;

    goForward();
  }, [isValid, cienteTermoRetroatividade, goForward]);


  return (
    <div className={sharedStyles.container}>
      <div className={styles.content}>
        <p className={`${sharedStyles.text} ${sharedStyles.emphasis}`}>
          Retroatividade
        </p>

        <p className={sharedStyles.text}>
          <span className={sharedStyles.emphasis}>Dr(a). {name}</span>, qual a data da sua 1ª contratação de seguro sem interrupção de vigência?
        </p>
        <QuestionDescription content={TOOLTIP_RETROATIVIDADE_RETROATIVIDADE} />

        <div className={sharedStyles.marginTop}>
          <DateInput
            id="dataContratao"
            label="Data da 1º contratação"
            type="string"
            placeholder="DD/MM/AAAA"
            maskType="date"
            value={formattedDateContratacao}
            onChange={(value) =>
              updateState({ dataContratacaoRetroatividade: dayjs(value, 'DD/MM/YYYY', true).toDate() })
            }
          />
          <ErrorMessage
            message={errors.dataContratacaoRetroatividade}
            isVisible={forwardPressed && !!errors.dataContratacaoRetroatividade}
          />
        </div>

        <div>
          <p className={styles.text}>
            Estou ciente e de acordo que a confirmação da data de retroatividade ocorrerá apenas num eventual sinistro,sendo obrigatório apresentar as apólices anteriores para comprovação. A apólice anterior não pode ter sido cancelada ou ter tido interrupção de vigência.
          </p>
        </div>
        <div className={styles.ciencia}>
          <CheckboxInput
            key="checkbox-ciencia-termos"
            id="termo"
            label="ESTOU CIENTE"
            name="termo"
            value="termo"
            checked={cienteTermoRetroatividade}
            onChange={onChangeCienciaTermo}
          />

          <ErrorMessage message="Por favor, para continuar aceite o termo" isVisible={forwardPressed && !cienteTermoRetroatividade} />
        </div>

        <div className={sharedStyles.marginNavigationButtons}>
          <NavigationButtons onClickForward={validate} onClickBack={goBack} />
        </div>
      </div>
    </div>
  );
}