import dayjs from "dayjs";
import { Cotacao, CotacaoRequest, CotacaoResponse } from "../types/api-corsier";
import { isArray } from "lodash";

interface SendRequest {
  url?: RequestInfo;
  requestConfig?: RequestInit;
  applyData?: (data: any) => void;
}

class ApiCrosier {
  readonly BASE_URL = 'https://ss.ss.crosier.com.br/api';

  readonly BASE_REQUEST_CONFIG = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  async cotar(cotacaoData: CotacaoRequest) {
    try {

      const url = '/cotacao/cotar'
      const requestConfig = {
        ...this.BASE_REQUEST_CONFIG,
        body: JSON.stringify(cotacaoData),
      };

      const response = await this.sendRequest({ requestConfig, url }) as CotacaoResponse;

      return this.processCotacaoData(response);
    } catch (error: any) {
      console.warn(error);
      throw Error(error.message || 'Something went wrong!');
    }
  }

  private processCotacaoData(data: CotacaoResponse): Cotacao {
    if (data.RESULT !== 'OK') {
      throw new Error(data.MSG);
    }

    const [{ cotacoes, requestData }] = data.DATA.flatMap(({ jsonData }) => {
      const requestData = {
        cpf: jsonData.cpf,
        crm: jsonData.crm,
        nome: jsonData.nome,
        email: jsonData.email,
        telefone: jsonData.telefone,
        dt_inicio_vigencia: dayjs(jsonData.dt_inicio_vigencia).format('YYYY-MM-DD'),
        renovacao: jsonData.renovacao,
        logradouro: jsonData.logradouro,
        numero: jsonData.numero,
        complemento: jsonData.complemento,
        bairro: jsonData.bairro,
        modalidade: jsonData.modalidade,
        cidade: jsonData.cidade,
        cep: jsonData.cep,
        uf: jsonData.uf,
        categorias: isArray(jsonData.categorias) ? jsonData.categorias : [jsonData.categorias],
        atividades_procedimentos: jsonData.atividades_procedimentos,
        retroatividade: jsonData.retroatividade,
        sinistros_24meses: jsonData.sinistros_24meses,
        expectativa_sinistros: jsonData.expectativa_sinistros,
        expectativa_sinistros_reivindicacoes_terceiros: jsonData.expectativa_sinistros_reivindicacoes_terceiros,
        residente: jsonData.residente,
        coberturas: jsonData.coberturas,
        pep: jsonData.pep,
        pep_nome: jsonData.pep_nome,
        pep_parentesco: jsonData.pep_parentesco,
      };

      return { cotacoes: jsonData.cotacoes, requestData }
    });

    return {
      uuid: data.DATA[0].uuid,
      cotacoes,
      requestData,
    };
  }

  private async sendRequest({ requestConfig, url }: SendRequest) {
    if (!requestConfig || !url) return;

    const {
      method,
      headers,
      body,
    } = requestConfig;

    try {
      const response = await fetch(
        this.BASE_URL + url,
        {
          method: method || 'GET',
          headers: headers || {},
          body: body || null,
        }
      );

      if (!response.ok) {
        throw new Error('Request failed!');
      }

      const data = await response.json();
      return data;
    } catch (error: any) {
      throw Error(error.message || 'Something went wrong!');
    }
  }
}

export default ApiCrosier;