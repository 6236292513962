/* eslint-disable react/jsx-no-target-blank */
import { useCallback, useContext, useMemo, useState } from "react";
import MultiStepsContext from "../../../../store/multi-steps-form-context";
import sharedStyles from '../../../SharedStyles.module.css';
import FormContext from "../../../../store/form-context";
import styles from './Pagamento.module.css';
import { METODOS_PAGAMENTO, URL_PRD } from "../../../../utils/constants";
import { CheckboxInput } from "../../../UI/checkbox-input/CheckboxInput";
import { Button } from "../../../UI/button/Button";
import { ErrorMessage } from "../../../error-message/ErrorMessage";
import useYupSchema from "../../../../hooks/use-yup-schema";
import Yup from "../../../../utils/yup";
import useHttp from "../../../../hooks/use-http";
import { FullscreenLoader } from "../../../UI/fullscreen-loader/FullscreenLoader";
import { Resumo } from "./components/resumo/Resumo";
import { ConfiguracaoPagamento } from "./components/configuracao-pagamento/ConfiguracaoPagamento";
import { Cartao } from "./components/cartao/Cartao";
import { removeSpecialCharacters } from "../../../../utils/remove-special-characters";
import { Alert } from "../../../UI/alert/Alert";
import dayjs from "dayjs";
import { CupomDesconto } from "./components/cupom-desconto/CupomDesconto";
import { isEmpty } from "lodash";
import selo from '../../../../img/selo-compra-segura.png';

const LabelTermo = () => (
  <span>
    Li e estou de acordo com as{' '}
    <a
      className={styles.termoLink}
      href="https://drive.google.com/file/d/1SX19qC0J7nAQUcX3JAirmoCayt9uBdPr/view?usp=drive_link"
      target="_blank"
    >condições gerais</a>
  </span>
);

const AlertError = () => {
  return (
    <div>
      <p>
        Não foi possível finalizar o pedido. Por favor, verifique seus dados e tente novamente. Ou {' '}
        <a
          href="https://api.whatsapp.com/send?phone=554230289900&text=Seguro%20Medico%20Protegido"
          className={styles.link}
          target="_blank"
        >
          entre em contato com nossa equipe pelo WhatsApp aqui.
        </a>
      </p>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  metodoPagamento: Yup.string().required(),
  vencimento: Yup.number()
    .when('isCartao', ([isCartao], schema) =>
      !isCartao ? schema.moreThan(0).required() : schema
    ),
  idPagamento: Yup.string().required(),
  isCartao: Yup.boolean(),
  numeroCartao: Yup.string()
    .when('isCartao', ([isCartao], schema) =>
      isCartao ? schema.required() : schema
    ),
  validade: Yup.string()
    .when('isCartao', ([isCartao], schema) =>
      isCartao ? schema.required() : schema
    ),
  nomeTitular: Yup.string()
    .when('isCartao', ([isCartao], schema) =>
      isCartao ? schema.required() : schema,
    ),
  cvv: Yup.string()
    .when('isCartao', ([isCartao], schema) =>
      isCartao ? schema.required() : schema,
    ),
});

export const ItemPagamento = ({
  id,
  name,
  value,
  label,
  checked,
  onChange,
}: ItemPropTypes) => (
  <div className={styles.item}>
    <input id={id} className={styles.input} type="radio" name={name} value={value} checked={checked} onChange={onChange} />
    <label htmlFor={`#${id}`} className={styles.inputLabel}>{label}</label>
  </div>
);

export const Pagamento = () => {
  const { goBack } = useContext(MultiStepsContext);
  const [forwardPressed, setForwardPressed] = useState(false);
  const { isLoading, sendRequest } = useHttp();
  const [showError, setShowError] = useState(false);
  const {
    name,
    metodoPagamento,
    dataNascimento,
    aceiteTermo,
    cotacaoData,
    cotacaoDataComDesconto,
    vencimento,
    idPagamento,
    numeroCartao,
    validade,
    nomeTitular,
    cvv,
    cpf,
    endereco,
    numero,
    complemento,
    bairro,
    cidade,
    uf,
    cep,
    email,
    updateState,
  } = useContext(FormContext);

  const cupomAplicado = !isEmpty(cotacaoDataComDesconto);

  const cotacaoSelecionada = useMemo(
    () => cupomAplicado
      ? cotacaoDataComDesconto
      : cotacaoData,
    [cotacaoData, cupomAplicado, cotacaoDataComDesconto],
  );

  const isCartao = useMemo(() => metodoPagamento === "CARTAO", [metodoPagamento]);

  const { isValid, errors } = useYupSchema(
    {
      metodoPagamento,
      vencimento,
      idPagamento,
      numeroCartao,
      validade,
      nomeTitular,
      cvv,
      isCartao,
    },
    validationSchema,
  );

  const onChangeFormaPagamento: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    updateState({
      metodoPagamento: event.target.value,
      idPagamento: '',
    })
  };

  const onChangeAceiteTermo = (_newValue: string) => {
    const newState = !aceiteTermo;
    updateState({ aceiteTermo: newState });
  };

  const enviarProposta = useCallback(async () => {
    if (!cotacaoSelecionada) return;

    const setResult = (dataObject: any) => {
      updateState({ propostaData: dataObject.DATA });
    }

    try {
      const bodyData = {
        cotacao_uuid: cotacaoSelecionada.uuid,
        quotationIdentifier: cotacaoSelecionada?.cotacoes[0]?.quotation.quotationIdentifier,
        dt_nascimento: dayjs(dataNascimento).format('DD/MM/YYYY'),
        dia_vencto: !isCartao ? vencimento : undefined,
        metodo_pagto: metodoPagamento,
        id_selecao_pagto: idPagamento,
      };

      await sendRequest({
        url: `${URL_PRD}/cotacao/enviarProposta`,
        requestConfig: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        },
        applyData: setResult,
      });

    } catch (error: any) {
      throw Error(error);
    }
  }, [
    vencimento,
    idPagamento,
    dataNascimento,
    metodoPagamento,
    cotacaoSelecionada,
    isCartao,
    sendRequest,
    updateState,
  ]);

  const checkout = useCallback(async () => {
    if (!cotacaoSelecionada) return;

    const setResult = (dataObject: any) => {
      updateState({ propostaData: dataObject.DATA });
    };

    const [exp_mes, exp_ano] = validade.split('/');
    const dados_cartao = metodoPagamento === 'CARTAO' ? {
      numero: removeSpecialCharacters(numeroCartao),
      nome: nomeTitular,
      cpf,
      cvv,
      exp_mes,
      exp_ano,
      endereco_cobranca: {
        logradouro: endereco,
        numero,
        complemento,
        bairro,
        cidade,
        uf,
        cep: removeSpecialCharacters(cep),
      }
    } : {};

    try {
      const bodyData = {
        cotacao_uuid: cotacaoSelecionada.uuid,
        dados_cartao,
      };

      await sendRequest({
        url: `${URL_PRD}/cotacao/checkout`,
        requestConfig: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        },
        applyData: setResult,
      });
    } catch (error: any) {
      throw Error(error);
    }
  }, [
    validade,
    cotacaoSelecionada,
    numero,
    numeroCartao,
    nomeTitular,
    endereco,
    bairro,
    cep,
    cidade,
    complemento,
    cpf,
    cvv,
    uf,
    metodoPagamento,
    sendRequest,
    updateState,
  ]);

  const sendRdStation = useCallback(async () => {
    const descricaoConversao = metodoPagamento === 'CARTAO' ? 'Compra com cartão realizada' : 'Boleto gerado';
    const conversionIdentifier = 'Médico Protegido - ' + descricaoConversao;
    const payload = {
      conversion_identifier: conversionIdentifier,
      email,
    };

    const data = {
      event_type: 'CONVERSION',
      event_family: 'CDP',
      payload: payload,
    };

    sendRequest({
      url: 'https://api.rd.services/platform/conversions?api_key=dzngQiXUsAvPcvxWWXQMKWHVfQcXloruuvIY',
      requestConfig: {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      },
    });
  }, [email, metodoPagamento, sendRequest]);

  const validate = useCallback(() => {
    setForwardPressed(true);

    if (!isValid || !aceiteTermo) return;

    const finalizar = async () => {
      try {
        await enviarProposta();
        await checkout();
        await sendRdStation();

        window.location.href = "http://medicoprotegido.sistemasulseguros.com.br/formulario/comprafinalizada";
      } catch (error) {
        console.warn(error);
        window.scroll(0, 0);
        setShowError(true);
      }
    }

    finalizar();
  }, [isValid, aceiteTermo, enviarProposta, checkout, sendRdStation]);

  return (
    <div className={sharedStyles.container}>
      <FullscreenLoader isLoading={isLoading} />
      <div className={styles.content}>
        <Alert
          message={<AlertError />}
          showAlert={showError}
          onClose={() => setShowError(false)}
        />
        <div className={styles.title}>
          <p className={sharedStyles.text}>
            Como será efetuado o pagamento
            <span className={sharedStyles.emphasis}> Dr(a). {name}</span>?
          </p>
        </div>

        <div className={styles.metodosContainer}>
          <div className={styles.metodosContent}>
            <CupomDesconto />
            <Resumo />
            {
              METODOS_PAGAMENTO.map(({ id, name, value, label }) => {
                const isSelected = metodoPagamento === value;
                const isCartao = id === 'cartao';
                return (
                  <div key={id}>
                    <ItemPagamento
                      id={id}
                      name={name}
                      value={value}
                      label={label}
                      checked={isSelected}
                      onChange={onChangeFormaPagamento}
                    />
                    {
                      isSelected && (
                        <div>
                          <ConfiguracaoPagamento isErrorVisible={forwardPressed} />
                          {isCartao && (<Cartao isErrorVisible={forwardPressed} />)}
                        </div>
                      )
                    }
                  </div>
                )
              })
            }
            <ErrorMessage message={errors.metodoPagamento} isVisible={forwardPressed && !!errors.metodoPagamento} />

            <div className={styles.aceite}>
              <CheckboxInput
                key="checkbox-termo"
                id="termo"
                label={<LabelTermo />}
                name="termo"
                value="termo"
                checked={aceiteTermo}
                onChange={onChangeAceiteTermo}
              />
              <ErrorMessage message="Por favor, para continuar aceite as condições gerais" isVisible={forwardPressed && !aceiteTermo} />
            </div>
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          <div className={styles.buttons}>
            <Button label="Confirmar pedido" onClick={validate} disabled={isLoading || !isValid || !aceiteTermo} />
            <Button label="Voltar" type="light" onClick={goBack} disabled={isLoading} />
          </div>
        </div>

        <div className={styles.seloContainer}>
          <img className={styles.selo} src={selo} alt="Selo compra segura" />
        </div>
      </div>
    </div>
  )
};


type ItemPropTypes = {
  id: string;
  label: string;
  name: string;
  value: string;
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}