// export const URL_HML = 'https://ss.ss-hom.crosier.com.br/api';
export const URL_PRD = 'https://ss.ss.crosier.com.br/api';

export const ESPECIALIDADES = [
  {
    id: "clinico",
    label: "Sou clínico e/ou atuo com medicina de urgência e emergência",
    name: "especialidade",
    value: "NO-SURGERY",
    tooltip: "Marque essa opção se você atua somente em área clínica e não realiza procedimentos em centro cirúrgicos e/ou atua em plantões atuando com urgência e emergência (estarão amparados atendimentos ambulatoriais).",
  },
  {
    id: "cirurgiao_anestesista",
    label: "Sou cirurgião (exceto plástico) ou anestesiologista",
    name: "especialidade",
    value: "SURGERY-EXCEPT-PLASTIC",
    tooltip: "Marque essa opção se você realiza procedimentos em centros cirúrgicos ou atua com especialidades cirúrgicas como ortopedia, cirurgia ginecológica, cirurgia pediátrica, entre outras especialidades cirúrgicas. Marque também se atuar com anestesiologia.",
  },
  {
    id: "obstetra",
    label: "Sou obstetra",
    name: "especialidade",
    value: "OBSTETRICIAN",
    tooltip: "Marque essa opção se você trabalha com obstetrícia - também estarão amparados procedimentos clínicos e cirúrgicos em geral.",
  },
  {
    id: "cirurgiao_plastico",
    label: "Sou cirurgião plástico",
    name: "especialidade",
    value: "PLASTIC-SURGERY",
    tooltip: "Marque essa opção se você atua como cirurgião plástico - as demais especialidades acima estarão automaticamente cobertas.",
  },
];

export const PROCEDIMENTOS = [
  {
    id: "esteticos",
    label: "Procedimentos estéticos minimamente invasivos",
    name: "procedimento",
    value: 'AESTHETIC-PROCEDURES',
    tooltip: "Procedimentos injetáveis como aplicação de toxina botulínica, peeling químico, aplicação de ácido hialuronico e similares, para fins estéticos, que não demandam a realização de grandes cortes e que não necessitam de internação.",
    especialidades: ["NO-SURGERY", "SURGERY-EXCEPT-PLASTIC", "OBSTETRICIAN"],
  },
  {
    id: "endoscopia",
    label: "Endoscopia e/ou colonoscopia",
    name: "procedimento",
    value: 'ENDOSCOPY-COLONOSCOPY',
    tooltip: "",
    especialidades: ["NO-SURGERY", "SURGERY-EXCEPT-PLASTIC", "OBSTETRICIAN", "PLASTIC-SURGERY"],
  },
  {
    id: "radioterapia",
    label: "Radioterapia e/ou quimioterapia e/ou imunoterapia",
    name: "procedimento",
    value: 'RADIOTHERAPY-CHEMOTHERAPY-IMMUNOTHERAPY',
    tooltip: "",
    especialidades: ["NO-SURGERY", "SURGERY-EXCEPT-PLASTIC", "OBSTETRICIAN", "PLASTIC-SURGERY"],
  },
  {
    id: "espeticos_especialidade",
    label: "Procedimentos estéticos relacionados à Especialidade Médica",
    name: "procedimento",
    value: 'AESTHETIC-PROCEDURES-MEDICAL-SPECIALTY',
    tooltip: "Para contratar este item, o médico deve possuir o Registro de Qualificação de Especialista (RQE) correspondente e estar habilitado e apto para realizar os procedimentos em questão. Exemplos incluem: Otorrinolaringologista com RQE que realiza otoplastia, Ginecologista com RQE que realiza cirurgia plástica íntima, Oftalmologista com RQE que realiza blefaroplastia. Caso o médico possua o título de Cirurgião Plástico, ele deverá contratar como tal.",
    especialidades: ["SURGERY-EXCEPT-PLASTIC", "OBSTETRICIAN"],
  },
  {
    id: "espeticos_especialidade",
    label: "Implante e/ou Transplante Capilar",
    name: "procedimento",
    value: 'HAIR-IMPLANT-TRANSPLANT',
    tooltip: "",
    especialidades: ["NO-SURGERY", "SURGERY-EXCEPT-PLASTIC", "OBSTETRICIAN"],
  },
];

export const RECLAMACOES = [
  {
    id: 'zero',
    label: 'Nenhuma',
    name: 'reclamacao',
    value: 0,
  },
  {
    id: 'uma',
    label: '1 reclamação',
    name: 'reclamacao',
    value: 1,
  },
  {
    id: 'duas',
    label: '2 reclamações',
    name: 'reclamacao',
    value: 2,
  },
  {
    id: 'tres',
    label: '3 reclamações ou mais',
    name: 'reclamacao',
    value: 3,
  },
];

export const RETROATIVIDADE = [
  {
    id: 'zero',
    label: 'Sem retroatividade',
    name: 'retroatividade',
    value: 0,
  },
  {
    id: 'um',
    label: '1 ano',
    name: 'retroatividade',
    value: 1,
  },
  {
    id: 'dois',
    label: '2 anos',
    name: 'retroatividade',
    value: 2,
  },
  {
    id: 'tres',
    label: '3 anos',
    name: 'retroatividade',
    value: 3,
  },
  {
    id: 'quatro',
    label: '4 anos',
    name: 'retroatividade',
    value: 4,
  },
  {
    id: 'cinco',
    label: '5 anos',
    name: 'retroatividade',
    value: 5,
  },
];

export const COBERTURAS = [
  {
    value: 100000,
    valor: '100 mil',
    valorFormatado: 'R$ 100.000,00',
    valorParcela: '550,00',
    valorAVista: '6.000,00',
  },
  {
    value: 300000,
    valor: '300 mil',
    valorFormatado: 'R$ 300.000,00',
    valorParcela: '850,00',
    valorAVista: '9.000,00',
  },
  {
    value: 500000,
    valor: '500 mil',
    valorFormatado: 'R$ 500.000,00',
    valorParcela: '1.250,00',
    valorAVista: '12.500,00',
  },
  {
    value: 1000000,
    valor: '1 milhão',
    valorFormatado: 'R$ 1.000.000,00',
    valorParcela: '2.250,00',
    valorAVista: '24.000,00',
  },
];

export const RECOMENDACAO_POR_ESPECIALIDADE = [
  {
    especialidade: 'PLASTIC-SURGERY',
    cobertura: 1000000,
  },
  {
    especialidade: 'NO-SURGERY',
    cobertura: 300000,
  },
  {
    especialidade: 'OBSTETRICIAN',
    cobertura: 500000,
  },
  {
    especialidade: 'SURGERY-EXCEPT-PLASTIC',
    cobertura: 500000,
  },
];

export const FRANQUIAS = [
  {
    especialidade: 'PLASTIC-SURGERY',
    franquia: '10% dos prejuízos indenizáveis com o mínimo de R$ 5.000,00',
  },
  {
    especialidade: 'NO-SURGERY',
    franquia: 'Sem franquia',
  },
  {
    especialidade: 'OBSTETRICIAN',
    franquia: '10% dos prejuízos indenizáveis com o mínimo de R$ 1.000,00'
  },
  {
    especialidade: 'SURGERY-EXCEPT-PLASTIC',
    franquia: 'Sem franquia',
  },
];

export const PROTECAO_COBERTURAS = {
  left: [
    'Danos materiais e corporais',
    'Danos morais e estéticos',
    'Honorários advocatícios',
    'Acordos judiciais e extrajudiciais',
    'Despesas emergenciais',
    'Gerenciamento de crises',
    'Perda de exames e laudos',
    'Omissão de socorro',
    'Cobertura para telemedicina',
  ],
  right: [
    'Extensão para PJ',
    'Calúnia, injúria e difamação',
    'Herdeiros, espólio e/ou representante legal',
    'Tratamento médico domiciliar',
    'Ato do bom samaritano',
    'Transporte de pacientes',
    'Chefe de equipe e/ou responsável médico',
    'Assistência jurídica',
  ],
};

export const DIFERENCIAIS = [
  {
    id: 'diferencial-1',
    title: 'Possibilidade de escolha do advogado',
    content: 'Livre escolha de advogado e sem limitação de custos de defesa (sujeitos a aprovação prévia da Seguradora).'
  },
  {
    id: 'diferencial-2',
    title: 'Proteção extra para cargos de liderança',
    content: 'Ampara para sua responsabilização como Chefe de Equipe ou Responsável Médico ou Diretor Médico, ainda que não tenha diretamente dado causa ao fato gerador da reclamação.'
  },
  {
    id: 'diferencial-3',
    title: 'Extensão para pessoa jurídica',
    content: 'Estende a cobertura para a pessoa jurídica da qual o segurado seja sócio, prestador de serviços ou empregado, exclusivamente motivada por atos profissionais do próprio segurado.'
  },
  {
    id: 'diferencial-4',
    title: 'Gerenciamento de crises',
    content: 'Despesas de gerenciamento de crises, evidenciada pela necessidade de contratação de empresa de comunicação e proteção de imagem decorrentes de uma reclamação ou expectativa de reclamação.'
  },
  {
    id: 'diferencial-5',
    title: 'Cobertura para o ato do bom samaritano',
    content: 'Ampara o atendimento realizado pelo segurado em uma emergência médica, acidente, primeiros socorros ou catástrofe, seja por causalidade ou por responder um pedido de ajuda, fora de seu expediente regular.'
  },
  {
    id: 'diferencial-6',
    title: 'Cobertura para dano estético',
    content: 'Ampara o dano estética, aquele que se caracteriza pela redução ou eliminação de padrão de beleza anterior ao fato gerador, mas sem a ocorrência de sequelas que interfira na funcionalidade do organismo ou na saúde do indivíduo. Não se considerada dano estético a insatisfação com resultado.'
  },
  {
    id: 'diferencial-7',
    title: 'Cobertura para dano moral puro',
    content: 'Cobertura para casos de dano moral que ocorrem sem a existência de dano corporal ou material. Ou seja, o dano moral que independe que a vítima demonstre prova de prejuízo.'
  },
  {
    id: 'diferencial-8',
    title: 'Cobertura para telemedicina e tratamento domiciliar',
    content: 'Sendo segurado pelo Médico Protegido, o profissional também possui cobertura para processos originados de atendimentos por telemedicina ou domiciliar.'
  },
  {
    id: 'diferencial-9',
    title: 'Cobertura nas 3 esferas',
    content: 'Cobertura na esfera cível, administrativa (CRM) e criminal.'
  },
  {
    id: 'diferencial-10',
    title: 'Assistência Jurídica',
    content: 'Serviço de atendimento exclusivo ao segurado, onde terá acesso a uma equipe jurídica para orientá-lo em demandas envolvendo situações emergenciais relacionadas diretamente com sua atividade profissional  no que diz respeito à relação médico/paciente. O Espaço é totalmente gratuito e não há limitação em sua utilização.'
  },
];

export const EXPECTATIVA_OPTIONS = [
  {
    id: "expectativa_nao",
    label: "Não",
    name: "expectativa",
    value: "nao",
  },
  {
    id: "expectativa_sim",
    label: "Sim",
    name: "expectativa",
    value: "sim",
  },
];

export const POSSUI_NOME_SOCIAL_OPTIONS = [
  {
    id: "possui_nome_social_nao",
    label: "Não",
    name: "possui_nome_social",
    value: "nao",
  },
  {
    id: "possui_nome_social_sim",
    label: "Sim",
    name: "possui_nome_social",
    value: "sim",
  },
];

export const RESIDENTE_OPTIONS = [
  {
    id: "residente_nao",
    label: "Não",
    name: "residente",
    value: "nao",
  },
  {
    id: "residente_sim",
    label: "Sim",
    name: "residente",
    value: "sim",
  },
];

export const METODOS_PAGAMENTO = [
  {
    id: 'cartao',
    name: 'pagamento',
    value: 'CARTAO',
    label: 'Cartão de crédito',
  },
  {
    id: 'boleto',
    name: 'pagamento',
    value: 'BOLETO',
    label: 'Boleto bancário',
  },
];

export const ESTADOS = [
  {
    id: 'est-option-sl',
    label: 'Selecione',
    value: '',
  },
  {
    id: 'est-option-AC',
    label: 'Acre',
    value: 'AC',
  },
  {
    id: 'est-option-AL',
    label: 'Alagoas',
    value: 'AL',
  },
  {
    id: 'est-option-ap',
    label: 'Amapá',
    value: 'AP',
  },
  {
    id: 'est-option-am',
    label: 'Amazonas',
    value: 'AM',
  },
  {
    id: 'est-option-ba',
    label: 'Bahia',
    value: 'BA',
  },
  {
    id: 'est-option-ce',
    label: 'Ceará',
    value: 'CE',
  },
  {
    id: 'est-option-df',
    label: 'Distrito Federal',
    value: 'DF',
  },
  {
    id: 'est-option-es',
    label: 'Espírito Santo',
    value: 'ES',
  },
  {
    id: 'est-option-go',
    label: 'Goiás',
    value: 'GO',
  },
  {
    id: 'est-option-ma',
    label: 'Maranhão',
    value: 'MA',
  },
  {
    id: 'est-option-mt',
    label: 'Mato Grosso',
    value: 'MT',
  },
  {
    id: 'est-option-ms',
    label: 'Mato Grosso do Sul',
    value: 'MS',
  },
  {
    id: 'est-option-mg',
    label: 'Minas Gerais',
    value: 'MG',
  },
  {
    id: 'est-option-pa',
    label: 'Pará',
    value: 'PA',
  },
  {
    id: 'est-option-pb',
    label: 'Paraíba',
    value: 'PB',
  },
  {
    id: 'est-option-pr',
    label: 'Paraná',
    value: 'PR',
  },
  {
    id: 'est-option-pe',
    label: 'Pernambuco',
    value: 'PE',
  },
  {
    id: 'est-option-pi',
    label: 'Piauí',
    value: 'PI',
  },
  {
    id: 'est-option-rj',
    label: 'Rio de Janeiro',
    value: 'RJ',
  },
  {
    id: 'est-option-rn',
    label: 'Rio Grande do Norte',
    value: 'RN',
  },
  {
    id: 'est-option-rs',
    label: 'Rio Grande do Sul',
    value: 'RS',
  },
  {
    id: 'est-option-ro',
    label: 'Rondônia',
    value: 'RO',
  },
  {
    id: 'est-option-rr',
    label: 'Roraima',
    value: 'RR',
  },
  {
    id: 'est-option-sc',
    label: 'Santa Catarina',
    value: 'SC',
  },
  {
    id: 'est-option-sp',
    label: 'São Paulo',
    value: 'SP',
  },
  {
    id: 'est-option-se',
    label: 'Sergipe',
    value: 'SE',
  },
  {
    id: 'est-option-to',
    label: 'Tocantins',
    value: 'TO',
  },
];

export const PEP = [
  {
    id: 'pep-option-sl',
    label: 'Selecione',
    value: '',
  },
  {
    id: 'pep-option-nao',
    label: 'Não sou PEP',
    value: 'N',
  },
  {
    id: 'pep-option-sim',
    label: 'Sim, sou PEP',
    value: 'S',
  },
  {
    id: 'pep-option-rel-proximo',
    label: 'Relacionamento próximo',
    value: 'R',
  },
];

export const GRAU_PARENTESCO_PEP = [
  {
    id: 'pep-parentesco-option-sl',
    label: 'Selecione',
    value: '',
  },
  {
    id: 'pep-parentesco-option-pm',
    label: 'Pai/Mãe',
    value: 'Pai/Mãe',
  },
  {
    id: 'pep-parentesco-option-ff',
    label: 'Filho/Filha',
    value: 'Filho/Filha',
  },
  {
    id: 'pep-parentesco-option-co',
    label: 'Cônjuge',
    value: 'Cônjuge',
  },
  {
    id: 'pep-parentesco-option-cc',
    label: 'Companheiro/Companheira',
    value: 'Companheiro/Companheira',
  },
  {
    id: 'pep-parentesco-option-ee',
    label: 'Enteado/Enteada',
    value: 'Enteado/Enteada',
  },
  {
    id: 'pep-parentesco-option-ou',
    label: 'Outros',
    value: 'Outros',
  },
];

export const TOOLTIP_RETROATIVIDADE = "A retroatividade garante cobertura para atendimentos anteriores a contratação do seguro, de acordo com o período selecionado e desde que não haja nenhum conhecimento prévio de forma judicial ou extrajudicial pelo segurado.";
export const TOOLTIP_RECLAMACAO = "O segurado sofreu qualquer tipo de reclamação extrajudicial, processo judicial civil, criminal ou ético administrativo, por dano(s) causado(s) pela prestação de seus serviços médicos nos últimos 24 meses? Se sim, quantas, ao todo, neste período?";
export const TOOLTIP_EXPECTATIVAS = "Mencionar se possuir conhecimento de qualquer fato ou circunstância que possa gerar uma reclamação extrajudicial, processo judicial civil, criminal ou ético administrativo ou de qualquer tipo similar de pedido de reparação de dano(s) causados(s) pela prestação de seus serviços";
export const TOOLTIP_FRANQUIA = "A franquia, também conhecida como Participação Obrigatória do Segurado (POS), é um valor estabelecido na apólice que será deduzida da indenização a ser recebida pelo segurado em caso de sinistro.";
export const TOOLTIP_RETROATIVIDADE_RETROATIVIDADE = "A retroatividade garante cobertura para atendimentos realizados dentro do período retroativo desde que haja uma série de renovações sem interrupção de vigência e devidamente comprovados pelas apólices de seguro.";
export const TOOLTIP_PEP = "Sigla utilizada para se referir à Pessoas Expostas Politicamente que ocupam ou tenham ocupado, nos últimos 5 anos, cargo político relevante em empresas públicas ou público privadas, no Brasil ou no exterior, considera-se com relacionamento próximos às PEP's seus parentes, sócios ou representantes.";
export const TOOLTIP_NOME_SOCIAL = "O nome social é o nome pelo qual pessoas trans preferem ser chamadas, não se tratando de apelido ou nome fantasia. Se você não utiliza um nome social, basta não utilizar este campo.";

export const EMAIL_REP_EXP = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const PHONE_REG_EXP = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/;

export const OPTIONS_VENCIMENTO = [
  {
    id: 'ven-0',
    name: 'vencimento',
    value: 0,
    label: 'Melhor data de vencimento da compra',
  },
  {
    id: 'ven-1',
    name: 'vencimento',
    value: 1,
    label: 'Sempre no dia 01',
  },
  {
    id: 'ven-5',
    name: 'vencimento',
    value: 5,
    label: 'Sempre no dia 05',
  },
  {
    id: 'ven-10',
    name: 'vencimento',
    value: 10,
    label: 'Sempre no dia 10',
  },
  {
    id: 'ven-14',
    name: 'vencimento',
    value: 14,
    label: 'Sempre no dia 14',
  },
  {
    id: 'ven-20',
    name: 'vencimento',
    value: 20,
    label: 'Sempre no dia 20',
  },
  {
    id: 'ven-28',
    name: 'vencimento',
    value: 28,
    label: 'Sempre no dia 28',
  },
];

export const SEGURADORAS = [
  {
    id: 'seg-option-sl',
    label: 'Selecione',
    value: '',
  },
  {
    id: 'seg-option-ak',
    label: 'Akad/Argo',
    value: 'Akad/Argo',
  },
  {
    id: 'seg-option-ar',
    label: 'Aruana',
    value: 'Aruana',
  },
  {
    id: 'seg-option-ch',
    label: 'Chubb',
    value: 'Chubb',
  },
  {
    id: 'seg-option-ex',
    label: 'Excelsior',
    value: 'Excelsior',
  },
  {
    id: 'seg-option-fai',
    label: 'Fairfax',
    value: 'Fairfax',
  },
  {
    id: 'seg-option-fat',
    label: 'Fator',
    value: 'Fator',
  },
  {
    id: 'seg-option-mat',
    label: 'MAPFRE',
    value: 'MAPFRE',
  },
  {
    id: 'seg-option-out',
    label: 'Outra',
    value: 'Outra',
  },
  {
    id: 'seg-option-po',
    label: 'Porto Seguro',
    value: 'Porto Seguro',
  },
  {
    id: 'seg-option-un',
    label: 'Unimed',
    value: 'Unimed',
  },
];
