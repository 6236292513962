import { DIFERENCIAIS } from '../../../../../../utils/constants';
import { Carousel } from '../../../../../UI/carousel/Carousel';
import styles from './Diferenciais.module.css';
import logo from '../../../../../../img/logo.png';

const ITEMS = DIFERENCIAIS.map(({ id, title, content }) => (
  <div className={styles.itemContainer} key={id}>
    <div className={styles.item}>
      <img
        decoding="async"
        src="https://medicoprotegido.sistemasulseguros.com.br/wp-content/uploads/2023/07/sadsadfasfasf.png"
        alt="Possibilidade de escolha do advogado"
        data-src="https://medicoprotegido.sistemasulseguros.com.br/wp-content/uploads/2023/07/sadsadfasfasf.png"
        className={styles.icon} />
      <div>
        <p className={styles.title}>{title}</p>
        <div className={styles.content}>
          <p>{content}</p>
        </div>
      </div>
    </div >
  </div>
));

export const Diferenciais = () => (
  <div className={styles.container}>
    <div className={styles.cardContainer}>
      <img src={logo} alt="Logo médico seguro" className={styles.image} />
      <img
        decoding="async"
        width="596"
        height="80"
        src="https://medicoprotegido.sistemasulseguros.com.br/wp-content/uploads/2023/07/Camada-1-1.png"
        className={styles.image}
        alt=""
      />
      <div className={styles.elementor}>
        <p>Benefícios Exclusivos</p>
      </div>
    </div>
    <Carousel items={ITEMS} />
  </div>
);
